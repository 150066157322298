import React, { Component } from 'react';
import { Button, Input, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { TreeItem } from '@material-ui/lab';
import { Add, Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { fetchSubSection,renameSubSection,removeSubSection,postExercise } from '../app/ActionCreator';
import ExerciseComponent from './ExerciseComponent';

const styles = {
    subSectionbuttondiv: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
   
    buttonColor: {
        color: 'white',
        background: '#143174',
        '&hover': {
            color: 'white',
            background: '#143174'
        }
    },
    treeroot: {
        flexGrow: 1
    },
}

class SubSectionComponent extends Component {
    constructor(props){
        super(props)
        this.state = {
            subsecid:0,
            subsec:'',
            secid:0,
            allowEdit:false
        }
    }

    changeSection = (event) => {
        this.setState({subsec:event.target.value})
    }

    handleToogle = (index) => {
        this.setState({allowEdit:!this.state.allowEdit})
        const secitem = this.props.subsection.subSection.filter((i) => i._id === index)
        this.setState({subsecid:secitem[0]._id})
        this.setState({subsec:secitem[0].name})
        this.setState({secid:secitem[0].section._id})
    }


    deleteSubSec = (id) => {
        this.props.removeSubSectionData(id)
    }


    createExercise = (subsecid,secid) => {
        this.props.postExerciseData('Exercise 1',subsecid,secid)
    }

    updateSubSection = () => {
        this.props.renameSubSectionData(this.state.subsecid,this.state.subsec,this.state.secid)
    }

    componentDidMount(){
        this.props.fetchSubSectionData()
    }

    render(){
       const { classes } = this.props
       const subsect = this.props && this.props.subsection && this.props.subsection.subSection.filter((v) => v.section._id === this.props.id)
       if(subsect && subsect.length > 0){
            return (
                <div>
                   {
                       subsect.map((v,i) => {
                           return(
                            <div key={v._id} style={{margin:'5px'}}>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <TreeItem 
                                        nodeId={v._id}
                                        label={
                                            this.state.subsecid === v._id && this.state.allowEdit ? <Input  
                                            disableUnderline={true}
                                            type="text"
                                            value={this.state.subsec}
                                            onChange={this.changeSection}
                                            onBlur={this.updateSubSection}/> : <p style={{color:'black'}}> {v.name} </p>
                                        }
                                        className={classes.treeroot} 
                                        onDoubleClick={() => this.handleToogle(v._id)}>
                                        <ExerciseComponent subsecid={v._id} secid={v.section._id}/>    
                                    </TreeItem>
                                    <div style={{display:'flex',justifyContent:'flex-end',position:'static',height:50}}>
                                        <IconButton onClick={() => this.deleteSubSec(v._id)}>
                                            <Delete/>
                                        </IconButton>     
                                    </div>
                                </div>
                                <div className={classes.subSectionbuttondiv}>
                                    <Button color="primary" onClick={() => this.createExercise(v._id,v.section._id)} startIcon={<Add />}>Add Exercise</Button>
                                </div>
                            </div>
                           )
                       })
                   }     
                </div>
            )
       }
       else{
           return null
       }
    }
}

const mapStateToProps = state => {
    return{
        subsection: state.subsection
    }
}

const mapDispatchToProps = dispatch => ({
    fetchSubSectionData:() => {
       dispatch(fetchSubSection())
    },
    renameSubSectionData:(id,name,section) => {
        dispatch(renameSubSection(id,name,section))
    },
    removeSubSectionData:(id) => {
        dispatch(removeSubSection(id))
    },
    postExerciseData:(name,subsection,section) => {
        dispatch(postExercise(name,subsection,section))
    } 

})

SubSectionComponent.propTypes = {
    classes:PropTypes.object,
    id:PropTypes.string.isRequired,
    subsection:PropTypes.object,
    fetchSubSectionData:PropTypes.func,
    renameSubSectionData:PropTypes.func,
    removeSubSectionData:PropTypes.func,
    postExerciseData:PropTypes.func
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SubSectionComponent));