import React, { Component } from 'react';
import { Button, TextField, List, ListItem, ListItemText, Switch, MenuItem, Typography,Snackbar } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { postUser, createOrganization, renameOrganization, fetchSandbox, fetchImage, fetchOrganizationById, fetchProfile } from './app/ActionCreator';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Add } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { Alert } from '@material-ui/lab';

const styles = {
    root:{
        marginTop:'60px'
    },
    buttonColor:{
        color:'white',
        background:'#143174',
        '&hover':{
            color:'white',
            background:'#143174'  
        }
    },
    typo:{
        flexGrow:1,
        textAlign:'center',
        marginTop:'30px',
        color:'black'
    }
}

class CreateOrganization extends Component {
    constructor(props){
        super(props)
        this.state = {
            name:'',
            user:[],
            //orguser:[],
            lab:[],
            showsnackbar:false,
            showsavesnackbar:false,
            orgid:'',
            hideuserbutton:false,
            hidesavebutton:true

        }
    }
    static getDerivedStateFromProps(props,state){
        if(props.match.params && props.match.params.id !== undefined){
            return {
                orgid:props.match.params.id
            }
        }
        
        return null
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.organization.organizationdetail !== this.props.organization.organizationdetail){
            this.setState({name:this.props.organization.organizationdetail.name})
        }
        if(prevProps.organization.organizationerr && this.props.organization.organizationerr !== null){
            this.setState({showsavesnackbar:true})
        }
        if(prevProps.organization.addorg !== this.props.organization.addorg){
           this.setState({orgid:this.props.organization.addorg.id})
        }
    }
    componentDidMount(){
        if(this.props.match.params !== undefined && this.props.match.params.id !== undefined){
            this.props.fetchOrganizationByIdData(this.props.match.params.id)
            
        }
        this.props.fetchProfileData()
        this.props.fetchSandboxData()
        this.props.fetchImageData()
    }

    

    addUser = () => {
        const data = {
            "firstname":"",
            "lastname":"",
            "password":"",
            "email":"",
            "role":"",
            "profile":""
        }
        this.setState({user:this.state.user.concat(data)})
       this.setState({hideuserbutton:true})
    }

    createUser = () => {
        const createuserdata = new FormData()
        createuserdata.append("email",this.state.user[0].email)
        createuserdata.append("role",this.state.user[0].role)
        createuserdata.append("first_name",this.state.user[0].firstname)
        createuserdata.append("last_name",this.state.user[0].lastname)
        createuserdata.append("password",this.state.user[0].password)
        createuserdata.append("organization",this.state.orgid)
        createuserdata.append("profile",this.state.user[0].profile)
        if(this.state.user[0].role === ''){
            createuserdata.append("role","Organisation Admin")
        }
        this.props.postUserData(createuserdata)
       
        this.setState({showsnackbar:true})
        this.setState({hidesavebutton:false})
    }

    handlelabToogle = (v) => {
        const uniquelab = this.state.lab.findIndex(x => x._id === v._id)
        if(uniquelab === -1){
           this.setState({lab:this.state.lab.concat(v)})
        }
    }

    updateorg = () => {
        const userorg = this.props.user && this.props.user.userlist.length > 0 && this.props.user.userlist[0].id
        const inst = this.props.Images.images.filter(val => this.state.lab.some(val1 => val.instance_name === val1.instance))
        const instance = []
        inst.map((v,i) => {
            if(instance.findIndex(v => v.id) === -1){
                instance.push(v.id)
            }
        })
        const userpara = []
        if(userpara.findIndex(x => x === userorg) === -1){
            userpara.push(userorg)
        }
        const updateorgdata = {
            "seed_user":userpara,
            "allowed_labs":instance
        }
        this.props.renameOrganizationData(this.state.orgid,updateorgdata)
        
    }

    hanldeUser = () => {
        const { classes } = this.props
        if(this.state.user && this.state.user.length > 0){
            return (
                <div>
                    {
                        this.state.user.map((v,i) => {
                            return (
                                <div key={i}>
                                    <TextField 
                                        id="firstname"
                                        label="First Name"
                                        style={{margin: 8}}
                                        placeholder="Enter First Name"
                                        fullWidth
                                        onChange={(e) => v['firstname'] = e.target.value}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}/>
                                    <TextField 
                                        id="lastname"
                                        label="Last Name"
                                        style={{margin: 8}}
                                        placeholder="Enter Last Name"
                                        fullWidth
                                        onChange={(e) => v['lastname'] = e.target.value}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                    <TextField 
                                        id="emailid"
                                        label="EmailID"
                                        style={{margin: 8}}
                                        placeholder="Enter Email ID"
                                        fullWidth
                                        onChange={(e) => v['email'] = e.target.value}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                    <TextField 
                                        id="password"
                                        label="Password"
                                        style={{margin: 8}}
                                        placeholder="Enter Password"
                                        fullWidth
                                        type="password"
                                        onChange={(e) => v['password'] = e.target.value}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                    <TextField 
                                        id="role"
                                        select
                                        label="Role"
                                        fullWidth
                                        style={{margin:8}}
                                        margin="normal"
                                        defaultValue='Organisation Admin'
                                        onChange={(e) => v['role'] = e.target.value}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}>
                                        <MenuItem value="Super Admin">Super Admin</MenuItem>
                                        <MenuItem value="Deviare Organisation Admin">Deviare Organisation Admin</MenuItem>
                                        <MenuItem value="Template User">Template User</MenuItem>
                                        <MenuItem value="Lab Creator">Lab Creator</MenuItem>
                                        <MenuItem value="Organisation Admin">Organisation Admin</MenuItem>
                                        <MenuItem value="Organisation User">Organisation User</MenuItem>        
                                    </TextField>
                                    {
                                        v['role'] === 'Organisation User' ? 
                                            <TextField
                                                id="profile"
                                                select
                                                label="Profile Name"
                                                fullWidth
                                                style={{margin:8}}
                                                margin="normal"
                                                onChange={(e) => v['profile'] = e.target.value}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}>
                                                {
                                                    this.props.profile.profiles.map((v,i) => {
                                                        return(
                                                            <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                                                        )    
                                                })}    
                                            </TextField>
                                            :null
                                    }
                                    <Button variant="contained" className={classes.buttonColor} style={{marginTop:10}} onClick={this.createUser}>Add</Button>                    
                                </div>    
                            )
                        })
                    }
                </div>
            )
        }
        else {
            return null
        }
    }

    displayError = () => {
        if(this.props.organization.organizationerr !== null){
            return(
                <Snackbar
                    open={this.state.showsavesnackbar}
                    autoHideDuration={3000}
                    onClose={() => this.setState({showsavesnackbar:false})}>
                    <Alert severity="error">Not Saved</Alert>    
                </Snackbar>
            )
        }
        else {
            return null;
        }
    }

   
    
    

    saveorg = () => {
        if(this.state.name !== null){
            const data = {
                "name":this.state.name,
                "seed_user":[],
                "allowed_labs":[]
            }
            this.props.createOrganizationData(data)
        }
        

    }

    render(){
        const { classes } = this.props
        return(
            <div className={classes.root}>
                <Typography variant="h3" noWrap className={classes.typo}>
                    Create Organisation
                </Typography>
               <div style={{display:'flex',justifyContent:'flex-end',marginTop:'15px'}}>
                    <Button variant="contained" component={Link} to={"/organization"} disabled={this.state.hidesavebutton} className={classes.buttonColor} onClick={this.updateorg}>Save</Button>
                </div>  
               <TextField
                id="name" 
                label="Name"
                fullWidth
                style={{margin:'8'}}
                margin="normal"
                value={this.state.name}
                onChange={(e) => this.setState({name:e.target.value})}
                onBlur={this.saveorg}
                InputLabelProps = {{
                    shrink:true
                }}/>
                <div style={{display:'flex',justifyContent:'flex-end',marginTop:'15px'}}>
                    <Button variant="contained" className={classes.buttonColor} onClick={this.addUser} disabled={this.state.hideuserbutton} startIcon={<Add/>}>Add User</Button>
                </div>
                {this.hanldeUser()}
                <Typography variant="h1" noWrap style={{marginTop:20}}>
                        List of Labs
                    </Typography>
                    <List component="nav" aria-label="secondary lab folder">
                        {
                             
                            this.props.sandbox.sandbox.filter((x) => x.type === "Lab" && x.status === "Published" ).map((v,i) => {
                                const labsubscribe = this.state.lab.some(x => x._id === v._id)
                                return(
                                    <ListItem key={v._id} >
                                    <ListItemText primary={v.title}/>
                                    <Switch
                                        edge="end"
                                        checked={labsubscribe}
                                        onChange={() => this.handlelabToogle(v)}
                                        inputProps={{ 'aria-labelledby': 'switch-list-label-lab' }}/>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    <Typography variant="h1" noWrap style={{marginTop:15}}>
                        List of Sandbox
                    </Typography >
                    <List component="nav" aria-label="secondary sandbox folder">
                        {
                            this.props.sandbox.sandbox.filter((x) => x.type === "Sandbox" && x.status === "Published").map((v,i) => {
                                const labsubscribe = this.state.lab.some(x => x._id === v._id)
                                return(
                                    <ListItem key={v._id} >
                                        <ListItemText primary={v.title}/>
                                        <Switch
                                            edge="end"
                                            checked={labsubscribe}
                                            onChange={() => this.handlelabToogle(v)}
                                            inputProps={{ 'aria-labelledby': 'switch-list-label-sandbox' }}/>
                                    </ListItem>
                                )
                            })
                        }
                    </List>
                    {this.props.user && this.props.user.userlist.length > 0 ? <Snackbar open={this.state.showsnackbar} autoHideDuration={3000} onClose={() => this.setState({showsnackbar:false})}>
                        <Alert severity="success"> User Added Successfully </Alert>
                    </Snackbar> : null}
                    {this.props.user && this.props.user.usererr !== null ? <Snackbar open={this.state.showsnackbar} autoHideDuration={3000} onClose={() => this.setState({showsnackbar:false})}>
                        <Alert severity="error"> Sorry please try again </Alert>
                    </Snackbar> : null}
                    {this.displayError()}      
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        user:state.user,
        organization:state.organization,
        Images:state.Images,
        sandbox:state.sandbox,
        profile:state.profile

    }
}

const mapDispatchToProps = dispatch => ({
    fetchOrganizationByIdData:(id) => {
        dispatch(fetchOrganizationById(id))
    },
    postUserData:(data) => {
        dispatch(postUser(data))
    },
    fetchSandboxData:() => {
        dispatch(fetchSandbox())
    },
    fetchImageData:() => {
        dispatch(fetchImage())
    },
    renameOrganizationData:(id,data) => {
        dispatch(renameOrganization(id,data))
    },
    fetchProfileData:() => {
        dispatch(fetchProfile())
    },
    createOrganizationData:(data) => {
        dispatch(createOrganization(data))
    }  

})

CreateOrganization.propTypes = {
    classes:PropTypes.object,
    match:PropTypes.object,
    fetchOrganizationByIdData:PropTypes.func,
    organization:PropTypes.object,
    fetchProfileData:PropTypes.func,
    profile:PropTypes.object,
    fetchSandboxData:PropTypes.func,
    sandbox:PropTypes.object,
    fetchImageData:PropTypes.func,
    Images:PropTypes.object,
    postUserData:PropTypes.func,
    user:PropTypes.object,
    createOrganizationData:PropTypes.func,
    renameOrganizationData:PropTypes.func,
    Images:PropTypes.object,
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CreateOrganization))