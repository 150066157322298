import React,{ Component } from 'react';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Delete, Edit } from '@material-ui/icons';
import { fetchOrganization, removeOrganization } from './app/ActionCreator';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import TableComponent from './Components/TableComponent';



const styles = {
    root:{
        marginTop:'60px'
    },
    buttonColor:{
        width:'20ch',
        height:'5ch',
        color:'white',
        marginRight:15,
        background:'#143174',
        '&hover':{
            color:'white',
            background:'#143174'  
        },
       
    },
    typo:{
        flexGrow:1,
        textAlign:'center',
        marginTop:'30px',
        color:'black'
    },
}

class Organization extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

  

    componentDidMount(){
        this.props.fetchOrganizationData()
    }
    render(){
        const { classes } = this.props 
        return(
            <div className={classes.root}>
                <Grid container>
                    <Grid item xs={10}/>
                    <Grid item xs={2}>
                        <Button component={Link} to={'/createorganization'} variant="contained"  className={classes.buttonColor}>
                            Create Organization
                        </Button> 
                    </Grid>
                </Grid>
                <Typography variant="h2" noWrap className={classes.typo}>
                    Organization List
                </Typography>
                <TableComponent 
                    cols={[
                        {field:'id',headerName:'SlNo',flex:1},
                        {field:'name',headerName:'Name',flex:1},
                        {field:'seed_user',headerName:'No.ofUsers',flex:1,renderCell:(value) => {
                            return (
                                <Typography>{value.row.seed_user.length}</Typography>
                            )
                        }},
                        {field:'created_at',headerName:'createdAt',flex:1},
                        {field:'action',headerName:'Action',flex:1,sortable:false,disableClickEventBubbling:true,renderCell:(params) => {
                            return(
                                <div style={{display:'flex'}}>
                                    <Link to={`/createorganization/${params.row.id}`}><IconButton><Edit/></IconButton></Link>
                                    <IconButton onClick={() => this.props.removeOrganizationData(params.row.id)}><Delete/></IconButton>
                                </div> 
                            )
                        }}
                    ]}
                    rows={this.props.organization.organizationList}
                    filter={{
                        items:[
                            {
                                columnField:'',
                                operatorValue:'',
                                value:''
                            }
                        ]
                    }
                }/>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        organization:state.organization
    }
}

const mapDispatchToProps = dispatch => ({
    fetchOrganizationData:() => {
        dispatch(fetchOrganization())
    },
    removeOrganizationData:(id) => {
        dispatch(removeOrganization(id))
    }
})

Organization.propTypes = {
    classes:PropTypes.object,
    fetchOrganizationData:PropTypes.func,
    organization:PropTypes.object,
    removeOrganizationData:PropTypes.func
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Organization))