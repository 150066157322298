import React from 'react';
import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';


const DropDownComponent = ({selectedValue, dropdownid,dropdownLabel,handleChange,children }) => {
  return(
    <div>
      <TextField
        id={dropdownid}
        select
        label={dropdownLabel}
        value={selectedValue}
        onChange={handleChange}
        style={{width:'50ch',margin:10}}
        InputLabelProps={{
          shrink: true,
        }}>
          {children}
      </TextField>
    </div> 
  )
}

DropDownComponent.propTypes = {
  children:PropTypes.element.isRequired,
  selectedValue:PropTypes.string.isRequired,
  handleChange:PropTypes.func.isRequired,
  dropdownLabel:PropTypes.string,
  dropdownid:PropTypes.string.isRequired,
}

export default DropDownComponent;