import React, { Component } from 'react';
import { Paper, Typography, Grid, Snackbar, CircularProgress, Box } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { fetchLab } from './app/ActionCreator';
import { connect } from 'react-redux';

const styles = {
    root:{
        display:'flex',
        marginTop:'60px',
    },
    rootCard:{
        minWidth:275,
        height:190,
        marginTop:'20px',
        background:'whitesmoke'
    },
    cardheadingText:{
        marginLeft:'20px',
        marginTop:'30px'
    },
    cardSubText:{
        display:'flex',
        justifyContent:'space-around',
        marginTop:'40px'
    },
    activeText:{
        fontSize:'14',
    },
    errorText:{
        fontSize:'14',
    },
    deleteText:{
        fontSize:'14',
    },
    activeNumber:{
        color:'#32CD32'
    },
    errorNumber:{
        color:'red'
    },
    deleteNumber:{
        color:'black'
    }

}


class Dashboard extends Component {
    constructor(props){
        super(props)
        this.state = {
            showSnackbar:true,
            errorsandbox:0,
            errorlab:0,
            deletedlab:0,
            deletedsandbox:0,
            activesandbox:0,
            activelab:0
        }
    }

    componentDidMount(){
        this.props.fetchLabData()
       
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.lab.labs !== this.props.lab.labs){
            const sandbox = this.props.lab.labs.filter((x) => x.lab_type === "sandbox")

            if(sandbox.length > 0){
                const activesanbox = sandbox.filter((x) => x.status === "running")
                if(activesanbox.length > 0){
                    this.setState({activesandbox:activesanbox.length})
                }
                const errorsanbox = sandbox.filter((x) => x.status === "error")
                if(errorsanbox.length > 0){
                    this.setState({errorsandbox:errorsanbox.length})
                }
                const deletesanbox = sandbox.filter((x) => x.status === "deleted")
                if(deletesanbox.length > 0){
                    this.setState({deletedsandbox:deletesanbox.length})
                }
            }
    
            const lab = this.props.lab.labs.filter((x) => x.lab_type === "lab")
    
            if(lab.length > 0){
                const activelb = lab.filter((x) => x.status === "running")
                if(activelb.length > 0){
                    this.setState({activelab:activelb.length})
                }
                const errorlb = lab.filter((x) => x.status === "error")
                if(errorlb.length > 0){
                    this.setState({errorlab:errorlb.length})
                }
                const deletelb = lab.filter((x) => x.status === "deleted")
                if(deletelb.length > 0){
                    this.setState({deletedlab:deletelb.length})
                }
            }
        }
    }


    render(){
        const { classes } = this.props
        
        return(
            <div className={classes.root}>
                <Grid item xs={4}>
                    <Paper className={classes.rootCard}>
                        <Typography variant="h4" noWrap className={classes.cardheadingText}>
                            SandBoxes
                        </Typography>
                        <div className={classes.cardSubText}>
                            <Typography variant="h6" noWrap className={classes.activeText}>
                                Active
                            </Typography>
                            <Typography variant="h6" noWrap className={classes.errorText}>
                                Error
                            </Typography>
                            <Typography variant="h6" noWrap className={classes.deleteText}>
                                Deleted
                            </Typography>
                        </div>
                        <div className={classes.cardSubText}>
                            <Typography variant="h2" noWrap className={classes.activeNumber}>
                                {this.state.activesandbox}
                            </Typography>
                            <Typography variant="h2" noWrap className={classes.errorNumber}>
                                {this.state.errorsandbox}
                            </Typography>
                            <Typography variant="h2" noWrap className={classes.deleteNumber}>
                                {this.state.deletedsandbox}
                            </Typography>
                        </div>
                    </Paper>    
                </Grid>
                <Grid item xs={4}/>
                <Grid item xs={4}>
                    <Paper className={classes.rootCard}>
                        <Typography variant="h4" noWrap className={classes.cardheadingText}>
                            Learning Labs
                        </Typography>
                        <div className={classes.cardSubText}>
                            <Typography variant="h6" noWrap className={classes.activeText}>
                                Active
                            </Typography>
                            <Typography variant="h6" noWrap className={classes.errorText}>
                                Error
                            </Typography>
                            <Typography variant="h6" noWrap className={classes.deleteText}>
                                Deleted
                            </Typography>
                        </div>
                        <div className={classes.cardSubText}>
                            <Typography variant="h2" noWrap className={classes.activeNumber}>
                                {this.state.activelab}
                            </Typography>
                            <Typography variant="h2" noWrap className={classes.errorNumber}>
                                {this.state.errorlab}
                            </Typography>
                            <Typography variant="h2" noWrap className={classes.deleteNumber}>
                                {this.state.deletedlab}
                            </Typography>
                        </div>
                    </Paper> 
                </Grid>
                <Snackbar
                    autoHideDuration={3000}
                    open={this.state.showSnackbar}
                    onClose={() => this.setState({showSnackbar:false})}>
                    <Alert severity="success">Welcome to Deviare</Alert>    
                </Snackbar>
                          
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        lab:state.lab
    }
}

const mapDispatchToProps = dispatch => ({
    fetchLabData:() => {
        dispatch(fetchLab())
    }
})

Dashboard.propTypes = {
    classes: PropTypes.object.isRequired,
    fetchLabData:PropTypes.func,
    lab:PropTypes.object,
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Dashboard));