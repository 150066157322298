import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

// colors
const primary = 'hsl(225, 100%, 26.7%)';
const primary_bg = 'hsl(225, 15%, 100%)';
const secondary = 'hsl(187, 45%, 60%)';
const tertiary = 'hsl(350, 45%, 60%)';
const quartenary = 'hsl(260, 45%, 60%)';
const white = '#dcdedf';
const black = '#343a40';
const darkBlack = 'rgb(36, 40, 44)';
const background = '#f5f5f5';
const warningLight = 'hsla(60, 45%, 60%, .3)';
const warningMain = 'hsla(60, 45%, 60%, .5)';
const warningDark = 'hsla(60, 45%, 60%, .7)';

// border
const borderWidth = 1;
const borderColor = 'hsla(250, 45%, 60%, 0.13)';

// breakpoints
const xl = 1920;
const lg = 1280;
const md = 960;
const sm = 600;
const xs = 0;

// spacing
const spacing = 8;

const theme = createMuiTheme({
  palette: {
    primary: { main: primary },
    secondary: { main: secondary },

    common: {
      black,
      darkBlack,
    },
    warning: {
      light: warningLight,
      main: warningMain,
      dark: warningDark
    },
    // Used to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
    background: {
      default: primary_bg,
      paper: background
    },
    spacing
  },
  mixins: {
    textfield: {
      variant: 'outlined'
    }
  },
  breakpoints: {
    // Define custom breakpoint values.
    // These will apply to Material-UI components that use responsive
    // breakpoints, such as `Grid` and `Hidden`. You can also use the
    // theme breakpoint functions `up`, `down`, and `between` to create
    // media queries for these breakpoints
    values: {
      xl,
      lg,
      md,
      sm,
      xs
    }
  },
  border: {
    borderColor: borderColor,
    borderWidth: borderWidth
  },
  overrides: {
    MuiExpansionPanel: {
      root: {
        position: 'static'
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: spacing * 2,
        paddingRight: spacing * 2,
        borderBottom: `${borderWidth}px solid ${borderColor}`,
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: borderColor,
        height: borderWidth
      }
    },
    MuiPrivateNotchedOutline: {
      root: {
        borderWidth: borderWidth
      }
    },
    MuiListItem: {
      divider: {
        borderBottom: `${borderWidth}px solid ${borderColor}`
      }
    },
    MuiDialog: {
      paper: {
        width: '100%',
        maxWidth: md,
      },
      paperWidthSm:{
        width: '100%',
        maxWidth: md,
      }
    },
    MuiListItemIcon: {
      root: {
        minWidth: '4vmin'
      }
    },
    MuiTooltip: {
      tooltip: {
        backgroundColor: darkBlack
      }
    },
    MuiExpansionPanelDetails: {
      root: {
        [`@media (max-width:  ${sm}px)`]: {
          paddingLeft: spacing,
          paddingRight: spacing
        }
      }
    }
  },
  typography: {
    fontFamily:[
      'Montserrat'
    ],
    h1: {
      fontWeight: 500,
      fontSize: '35px',
      lineHeight: 1.2,
      letterSpacing: '-0.24px',
    },
    h2: {
      fontWeight: 500,
      fontSize: '29px',
      lineHeight: 1.2,
      letterSpacing: '-0.24px'
    },
    h3: {
      fontWeight: 500,
      fontSize: '24px',
      lineHeight: 1.2,
      letterSpacing: '-0.06px'
    },
    h4: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: 1.2,
      letterSpacing: '-0.06px'
    },
    h5: {
      fontWeight: 500,
      fontSize: '18px',
      lineHeight: 1.2,
      letterSpacing: '-0.05px'
    },
    h6: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 1.25,
      letterSpacing: '-0.05px'
    },
    subtitle1: {
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: 1.5,
      letterSpacing: '-0.05px'
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: 1.25,
      letterSpacing: '-0.05px'
    },
    body1:{
      fontWeight: 400,
      fontSize: '14px',
    },
    body2:{
      fontWeight: 400,
      fontSize: '12px',
      color: '#546e7a'
    },
    useNextVariants: true,
    button:{
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: 1.75,
      textTransform: 'normal'
    }
  }
});
 
const responsiveTheme = responsiveFontSizes(theme);

export default responsiveTheme;
