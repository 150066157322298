import React,{ Component } from 'react';
import { TextField, Box, Paper, Button, IconButton, Typography, InputAdornment, Tooltip, ClickAwayListener, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { updatePassword } from './app/ActionCreator';
import { connect } from 'react-redux';

const styles = {
    headPaper:{
        borderWidth:2,
        borderColor:'white',
        background:'white'
    },
    buttonColor:{
        height:'20%',
        width:'90%',
        textAlign:'center',
        marginTop:60,
        marginLeft:20,
        marginRight:20,
        marginBottom:40,
        color:'white',
        background:'#143174',
        '&hover':{
            color:'white',
            background:'#143174'  
        }
    },
    typo:{
        flexGrow:1,
        textAlign:'center',
        marginTop:'30px'
    },
    img:{
        display:'block',
        marginLeft:'auto',
        marginRight:'auto',
        width:'40%',
        marginTop:'20px'
    },
    customWidth: {
        maxWidth: 500,
    },

}


class ResetPassword extends Component{
    constructor(props){
        super(props)
        this.state = {
            newPassword:'',
            confirmPassword:'',
            shownewPassword:false,
            showconfirmPassword:false,
            validatenewPassword:false,
            validateConfirmPassword:false,
            showTooltip:false,
            showsuccess:false,
            showerror:false
        }
        this.handleChange = this.handleChange.bind(this)
        
    }
    toogleNewPassword = () => {
        this.setState({shownewPassword:!this.state.shownewPassword})
    }

    toggleConfirmPassword = () => {
        this.setState({showconfirmPassword:!this.state.showconfirmPassword})
    }

    handleChange = (e) => {
        this.setState({[e.target.name]:e.target.value})
    }

    validate = (type) => {
        if(type === "newPassword"){
            const regexpassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
            if(regexpassword.test(this.state.newPassword) && this.state.newPassword !== null){
                this.setState({validatenewPassword:false})
            }
            else{
                this.setState({validatenewPassword:true})
            }
        }
        else{
            if(this.state.newPassword === this.state.confirmPassword && this.state.confirmPassword !== null){
                this.setState({validateConfirmPassword:false})
            }
            else{
                this.setState({validateConfirmPassword:true})
            }
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.resetpasswordsuccess.resetpasswordsuccess !== this.props.resetpasswordsuccess.resetpasswordsuccess){
            this.setState({showsuccess:true})
        }
        if(prevProps.resetpasswordfailed.resetpasswordfailed !== this.props.resetpasswordfailed.resetpasswordfailed){
            this.setState({showerror:true})
        }
    }

    resetpassword = () => {
        if(!this.state.validatenewPassword && !this.state.validateConfirmPassword){
            const resetform = new FormData()
            resetform.append('token',this.props.match.params.token)
            resetform.append('new_password',this.state.newPassword)
            this.props.updatePasswordData(resetform)
        }
        
    }

    errorsnackbar = () => {
        return (
            <Snackbar
                open={this.state.showerror}
                autoHideDuration={3000}
                onClose={() => this.setState({showerror:false})}>
                <Alert severity="error">{this.props.resetpasswordfailed.resetpasswordfailed}</Alert>    
            </Snackbar>
        )
    }

    successsnackbar = () => {
        return (
            <Snackbar
                open={this.state.showsuccess}
                autoHideDuration={3000}
                onClose={() => this.setState({showsuccess:false})}>
                <Alert severity="success">{this.props.resetpasswordsuccess.resetpasswordsuccess}</Alert>    
            </Snackbar>
        )
    }

    render(){
        const { classes } = this.props
        return(
            <div>
                <Box 
                display="flex"
                justifyContent="center"
                alignItems="center"
                minHeight="100vh">  
               <Paper className={classes.headPaper}>
                   <Typography variant="h1" className={classes.typo}>Update your Password</Typography>
                   <ClickAwayListener onClickAway={() => this.setState({showTooltip:false})}>
                   <Tooltip 
                        title={
                            <React.Fragment>
                                <Typography variant="h3" noWrap>Password must contain the following:</Typography>
                                <Typography variant="body2" noWrap> - A lowercase letter</Typography>
                                <Typography variant="body2" noWrap> - A capital (uppercase) letter</Typography>
                                <Typography variant="body2" noWrap> - A number</Typography>
                                <Typography variant="body2" noWrap> - Minimum 8 characters</Typography>
                            </React.Fragment>
                        }
                        open={this.state.showTooltip}
                        onClose={() => this.setState({showTooltip:false})} 
                        className={{ tooltip:classes.customWidth }}
                        placement="right">
                   <TextField
                    id="standard-full-width"
                    style={{ marginTop:30, marginLeft:20,marginRight:20,marginBottom:30, width:'90%',height:'5%' }}
                    placeholder="New Password"
                    name="newPassword"
                    value={this.state.newPassword}
                    type={this.state.shownewPassword ? "text" : "password"}
                    onFocus={() => this.setState({showTooltip:true})}
                    onBlur={() => this.validate("newPassword")}
                    onChange={this.handleChange}
                    error={this.state.validatenewPassword}
                    helperText={this.state.validatenewPassword  ? "Please Enter Strong Password" : ""}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment:(
                            <InputAdornment position="end">
                                <IconButton aria-label="toggle password visibility" onClick={this.toogleNewPassword}>
                                    {this.state.shownewPassword ? <Visibility/> : <VisibilityOff/>}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}/>
                    </Tooltip>
                    </ClickAwayListener>
                    <TextField 
                        id="standard-full-width"
                        style={{ margin:20, width:'90%',height:'5%' }}
                        placeholder="Confirm Password"
                        name="confirmPassword"
                        value={this.state.confirmPassword}
                        type={this.state.showconfirmPassword ? "text" : "password"}
                        onBlur={() => this.validate("confirmPassword")}
                        onChange={this.handleChange}
                        error={this.state.validateConfirmPassword}
                        helperText={this.state.validateConfirmPassword ? "Password Does not match" : ""} 
                        InputLabelProps={{
                            shrink:true,
                        }}
                        InputProps={{
                            endAdornment:(
                                <InputAdornment position="end">
                                    <IconButton aria-label="toggle password visibility" onClick={this.toggleConfirmPassword}>
                                        {this.state.showconfirmPassword ? <Visibility/> : <VisibilityOff/>}
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                        /> 
                    <Button className={classes.buttonColor} onClick={this.resetpassword}>Reset Password </Button>     
               </Paper>
               </Box>
               {this.errorsnackbar()}
               {this.successsnackbar()}  
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        resetpasswordsuccess:state.resetpassword,
        resetpasswordfailed:state.resetpassword
    }
}

const mapDispatchToProps = dispatch => ({
    updatePasswordData:(data) => {
        dispatch(updatePassword(data))
    }
})

ResetPassword.propTypes = {
    classes:PropTypes.object.isRequired,
    match:PropTypes.object.isRequired,
    resetpasswordsuccess:PropTypes.string,
    resetpasswordfailed:PropTypes.string,
    updatePasswordData:PropTypes.func

}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ResetPassword));