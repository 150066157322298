import React,{ Component } from 'react';
import { Typography,  TableBody, TableCell,  TableRow, Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { fetchLab } from './app/ActionCreator';
import { connect } from 'react-redux';
import EditStatusComponent from './Components/EditStatusComponent';
import { Link } from 'react-router-dom';
import TableComponent from './Components/TableComponent';

const styles = {
    root:{
        marginTop:'60px'
    },
    typo:{
        flexGrow:1,
        textAlign:'center',
        marginTop:'30px'
    },
    table: {
        minWidth: 650,
    },
    tableContainer:{
        marginTop:'20px',
        border:'none',
        background:'white'
    }
}

class MySandBoxes extends Component{
    constructor(props){
        super(props)
        this.state = {

        }
    }
    


    componentDidMount(){
        this.props.fetchLabData()
    }

    roleBasedAccess = () => {
        return(
            <TableComponent 
                cols={[
                    {field:'id',headerName:'Sl.No',flex:1},
                    {field:'lab_name',headerName:'Lab Name',flex:1},
                    {field:'created_by.organization_name',headerName:'Organisation',flex:1,valueFormatter:(params) => params.row.created_by.organization_name},
                    {field:'created_by.first_name',headerName:'User',flex:1,valueFormatter:(params) => params.row.created_by.first_name + params.row.created_by.last_name},
                    {field:'status',headerName:'Status',renderCell:(params) => {
                        if(params.row.status === 'running'){
                            return(
                                <EditStatusComponent
                                    status={params.row.status}
                                    statuscolor='#32CD32'/>
                            )
                        }
                        else if(params.row.status === 'error'){
                            return(
                                <EditStatusComponent
                                    status={params.row.status}
                                    statuscolor='red'/>
                            )
                        }
                        else {
                            return(
                                <EditStatusComponent
                                    status={params.row.status}
                                    statuscolor='black'/> 
                            )
                        }
                    }},
                    {field:'action',headerName:'Details',sortable:false,disableClickEventBubbling:true,flex:1,renderCell:(params) => {
                        return(
                            <Link to={`/sandbox/${params.row.name}`}><Button color="primary">View Details</Button></Link>
                        )
                    }}

                ]}
                rows={this.props.lab.labs.filter((x) => x.lab_type === "sandbox")}
                    filter={{
                        items:[
                            {
                                columnField:'',
                                operatorValue:'',
                                value:''
                            }
                        ]
                    }
                }/> 
            )
        
    }


    render(){
        const { classes } = this.props
        return(
            <div className={classes.root}>
               <Typography variant="h2" noWrap className={classes.typo}>
                    Sandboxes
                </Typography>
                {this.roleBasedAccess()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        lab:state.lab
    }
}

const mapDispatchToProps = dispatch => ({
    fetchLabData:() => {
        dispatch(fetchLab())
    }
})

MySandBoxes.propTypes = {
    classes:PropTypes.object.isRequired,
    lab:PropTypes.object,
    fetchLabData:PropTypes.func
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(MySandBoxes));