import React,{ Component } from 'react';
import  { Button, Typography, Grid,  MenuItem, Menu, IconButton, Link } from '@material-ui/core';
import Modals from './Components/Modals';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { fetchImage,postImage, removeImage, updateImageStatus } from './app/ActionCreator';
import { connect } from 'react-redux';
import EditStatusComponent from './Components/EditStatusComponent';
import { Edit, Delete } from '@material-ui/icons';
import TableComponent from './Components/TableComponent';
import { Formik } from "formik";
import * as Yup from 'yup';
import { InstanceFormComponent } from './Components/InstanceFormComponent';

const styles = {
    root:{
        marginTop:'60px',
    },
    typo:{
        flexGrow:1,
        textAlign:'center',
        marginTop:'30px',
        color:'black'
    },
    headingpaper:{
        width:'100%',
        height:40,
        marginTop:'30px',
        background:'white',
        borderColor:'white',
    },
    buttonColor:{
        color:'white',
        background:'#143174'
    },
    formControl: {
        margin:'8px',
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: '16px',
    },
    formRoot:{
        margin:'8px',
        width: '25ch',
    },
    modalButton:{
        color:'white',
        background:'#143174',
        marginRight:'10px'
    }
}

const validationSchema = Yup.object({
    name:Yup.string().required('Enter a name').matches(/^(?:[a-z](?:[-a-z0-9]{0,61}[a-z0-9])?)$/, 'Enter a valid name'),
    cpu:Yup.string().required("Select Cpu"),
    ram:Yup.string().required("Select Ram"),
    disk:Yup.string().required("Select Disk")
})

class AdminImages extends Component{
    constructor(props){
        super(props)
        this.state = {
            imageModal:false,
            anchorEl:null,
            imageId:''
        }
    }
    
   

    componentDidMount(){
        this.props.fetchImageData()
    }


    
    handleMenu = (event,id) => {
        this.setState({anchorEl:event.currentTarget})
        this.setState({imageId:id})
    }

    handleClose = (ev) => {
        this.setState({anchorEl:null})
        const data = {
            "status":ev.target.innerText
        }
        this.props.updateImageStatusData(this.state.imageId,data)
    }
    
    submitValues = ({ name, cpu, ram, disk }) => {
        this.props.postImageData(name,cpu,ram,disk)
        this.setState({imageModal:false})
    }
    

    
    render(){
        const { classes } = this.props;
        const values = { name: "", cpu: "", ram: "", disk: "" };
        return(
            <div className={classes.root}>
                   <Grid container direction="row" justify="flex-end" alignItems="flex-start">
                       <Button variant="contained" className={classes.buttonColor} onClick={() => this.setState({imageModal:true})}>
                           Create Images
                        </Button> 
                   </Grid>
                <Typography variant="h2" noWrap className={classes.typo}>
                    Virtual Machine Image
                </Typography>
                <TableComponent 
                    cols={[
                        {field:'id',headerName:'SlNo',flex:1},
                        {field:'instance_name',headerName:'Virtual Machine Image',flex:1},
                        {field:'created_at', headerName:'createdAt',flex:1},
                        {field:'created_by',headerName:'createdBy',flex:1,valueFormatter:(params) => params.row.created_by.first_name + params.row.created_by.last_name},
                        {field:'ip_address',headerName:'IpAddress',flex:1,renderCell:(value) => {return(<Link target={"_blank"} onClick={() => window.open(`http://${value.row.ip_address}/project`,'_blank')}>{value.row.ip_address}</Link>)}},
                        {field:'status',headerName:'Status',flex:1,renderCell:(value) => 
                            {
                                if(value.row.status === 'Resuming' || value.row.status === 'Running'){
                                    return(
                                        <EditStatusComponent
                                            status={value.row.status}
                                            statuscolor='#32CD32'
                                            showDropDown={false}/>
                                    )
                                }
                                else if(value.row.status === 'Suspending' || value.row.status === 'suspending' || value.row.status === 'error'){
                                    return(
                                        <EditStatusComponent
                                            status={value.row.status}
                                            statuscolor='red'
                                            showDropDown={false}/>
                                    ) 
                                }
                                else{
                                    return(
                                        <EditStatusComponent
                                            status={value.row.status}
                                            statuscolor='#143174'
                                            showDropDown={false}/> 
                                    )
                                }
                            } 
                        },
                        {field:'action',headerName:'Action',sortable:false,flex:1,disableClickEventBubbling:true,renderCell:(params) => {
                            return(
                                <div style={{display:'flex'}}>
                                    <IconButton 
                                        aria-controls="simple-menu" 
                                        aria-haspopup="true"
                                        onClick={(ev,id) => this.handleMenu(ev,params.row.id)}>
                                        <Edit/>
                                    </IconButton>   
                                    <IconButton onClick={() => this.props.removeImageData(params.row.id)}>
                                        <Delete/>
                                    </IconButton>
                                </div> 
                            )
                        }}
                    ]}
                    rows={this.props.Images.images}
                    filter={{
                        items:[
                            {
                                columnField:'',
                                operatorValue:'',
                                value:''
                            }
                        ]
                        
                    }}/>   
                <Menu 
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}>
                    <MenuItem value="Resume" onClick={this.handleClose}>Resume</MenuItem>
                    <MenuItem value="Suspend" onClick={this.handleClose}>Suspend</MenuItem>
                    <MenuItem value="Freeze" onClick={this.handleClose}>Freeze</MenuItem>
                </Menu>     
                <div>
                <Modals 
                    show={this.state.imageModal}
                    title="Create Instance"
                    handleClose={() => this.setState({imageModal:false})}
                    showSubmit={false}
                    size="xs">
                       <Formik
                            render={props => <InstanceFormComponent {...props} />}
                            initialValues={values}
                            validationSchema={validationSchema}
                            onSubmit={this.submitValues}/>     
                    </Modals>
                </div> 
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        Images:state.Images,
    }
}

const mapDispatchToProps = dispatch => ({
    fetchImageData:() => {
        dispatch(fetchImage())
    },
    postImageData:(name,cpu,ram,storage) => {
        dispatch(postImage(name,cpu,ram,storage))
    },
    removeImageData:(id) => {
        dispatch(removeImage(id))
    },
    updateImageStatusData:(id,data) => {
        dispatch(updateImageStatus(id,data))
    }
})

AdminImages.propTypes = {
    classes:PropTypes.object.isRequired,
    Images:PropTypes.object,
    fetchImageData:PropTypes.func,
    postImageData:PropTypes.func,
    removeImageData:PropTypes.func,
    updateImageStatusData:PropTypes.func,
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AdminImages))