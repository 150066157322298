import * as ActionTypes from './ActionTypes';
const initialState = {
    isLoading:false,
    excersie:[],
    errMess:null
}

export const exercise=(state=initialState,action) => {
    switch(action.type){
        case ActionTypes.GET_EXERCISE:
            return {
                ...state,
                excersie:action.payload,
                isLoading:false,
                errMess:null
            }
        case ActionTypes.EXERCISE_LOADING:
            return {
                ...state,
                exercise:[],
                isLoading:true,
                errMess:null
            }
        case ActionTypes.EXERCISE_FAILED:
            return {
                ...state,
                exercise:[],
                isLoading:false,
                errMess:action.payload
            }        
        default:
            return state           
    }
}