import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import PropTypes from 'prop-types';

const TableComponent = ({cols,rows, filter}) => {
    return(
        <div style={{ height: 500, width: '100%' }}>
            <DataGrid rows={rows} columns={cols}  filterModel={filter}/>
        </div>
    )
}

TableComponent.propTypes = {
    cols:PropTypes.array.isRequired,
    rows:PropTypes.array.isRequired,
    filter:PropTypes.object.isRequired,
}

export default TableComponent;