import * as ActionTypes from './ActionTypes';

const initialState = {
    organizationList:[],
    organizationdetail:{},
    isLoading:false,
    organizationerr:null,
    addorg:{}
}

export const organization = (state = initialState,action) => {
    switch(action.type){
        case ActionTypes.GET_ORGANIZATION:
            return {
                ...state,
                organizationList:action.payload.org,
                organizationdetail:{},
                isLoading:false,
                organizationerr:null,
                addorg:{}
            }
        case ActionTypes.GET_ORGANIZATION_BY_ID:
            return {
                ...state,
                organizationList:[],
                organizationdetail:action.payload.org,
                isLoading:false,
                organizationerr:null,
                addorg:{}
            }
        case ActionTypes.CREATE_ORGANIZATION:
            return {
                ...state,
                organizationList:[],
                organizationdetail:{},
                isLoading:false,
                organizationerr:null,
                addorg:action.payload.org
            }
        case ActionTypes.ORGANIZATION_LOADING:
            return {
                ...state,
                organizationList:[],
                organizationdetail:{},
                isLoading:true,
                organizationerr:null,
                addorg:{}
            }
        case ActionTypes.ORGANIZATION_ERROR:
            return {
                ...state,
                organizationList:[],
                organizationdetail:{},
                isLoading:false,
                organizationerr:action.payload,
                addorg:{}
            }
        default:
            return state                            
    }
}