import React from 'react';
import PropTypes from 'prop-types';

const EditStatusComponent = ({status,statuscolor}) => {
    
    return (
        <span style={{color:statuscolor}}>{status}</span>
    )
    
    
}

EditStatusComponent.propTypes = {
    statuscolor:PropTypes.string.isRequired,
    status:PropTypes.string.isRequired
}
export default EditStatusComponent