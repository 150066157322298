import React,{ Component } from 'react';
import { Grid, Box } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import TopNavigator from './Components/TopNavigator';
import { fetchSandbox } from './app/ActionCreator';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import UserComponent from './Components/UserComponent';
import RoleComponent from './Components/RolesComponent';
import CreateUserComponent from './Components/CreateUserComponent';
import CreateRoleComponent from './Components/CreateRoleComponent';
import { connect } from 'react-redux';


const styles = {
    root:{
        marginTop:'60px'
    },
    
    
}

class Roles extends Component{
    constructor(props){
        super(props)
        this.state = {
            path:'profiles',
            orgid:''
            
        }
    }

    static getDerivedStateFromProps(props,state){
        const { user } = JSON.parse(localStorage.getItem("user"))
        return {
            orgid:user.organization
        }
        
    }

    

    componentDidMount(){
        this.props.fetchSandboxData()
    }

    displayTabData = () => {
        if(this.state.path === 'users'){
            
            return(
               <Link to={'/users'} style={{textDecoration:'none'}}> 
                <UserComponent id={this.state.orgid}/>
               </Link> 
            )
        }
        else if(this.state.path === 'profiles'){
            return(
                <RoleComponent/> 
            )
        }
        else if(this.state.path === 'createusers'){
            return(
                <CreateUserComponent/>
            )
        }
        else {
            return(
                <CreateRoleComponent data={this.props.sandbox.sandbox.filter((x) => x.status === 'Published')}/>
            )
        }
    }

    render(){
        const { classes } = this.props
        return(
            <div className={classes.root}>
                <Grid container spacing={5}>
                    <Grid item xs={3}>
                      <TopNavigator 
                        title='Users'
                        handleClick={() => this.setState({path:'users'})}
                        isActive={this.state.path === 'users' ? true : false}/>  
                    </Grid>
                    <Grid item xs={3}>
                      <TopNavigator 
                        title='Profiles'
                        handleClick={() => this.setState({path:'profiles'})}
                        isActive={this.state.path === 'profiles' ? true : false}/>  
                    </Grid>
                    <Grid item xs={3}>
                      <TopNavigator 
                        title='Create Users'
                        handleClick={() => this.setState({path:'createusers'})}
                        isActive={this.state.path === 'createusers' ? true : false}/>  
                    </Grid>
                    <Grid item xs={3}>
                      <TopNavigator 
                        title='Create Profile'
                        handleClick={() => this.setState({path:'createprofile'})}
                        isActive={this.state.path === 'createprofile' ? true : false}/>  
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid xs={10}>
                        {this.displayTabData()}
                    </Grid>
                    <Grid xs={2}/>
                </Grid>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        sandbox:state.sandbox
    }
}

const mapDispatchToProps = dispatch => ({
    fetchSandboxData:() => {
        dispatch(fetchSandbox())
    }
})

Roles.propTypes = {
    classes:PropTypes.object.isRequired,
    sandbox:PropTypes.array,
    fetchSandboxData:PropTypes.func
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Roles));