import React, { useState, useEffect } from 'react';
import { IconButton, Menu, MenuItem, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MoreVert } from '@material-ui/icons';
import EditStatusComponent from './EditStatusComponent';
import TableComponent from './TableComponent';
import { connect } from 'react-redux';
import { fetchUser } from '../app/ActionCreator';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    typo:{
        flexGrow:1,
        textAlign:'center',
        marginTop:'30px',
        color:'black'
    },
}))

function UserComponent(props){
    const [anchorRef,setanchorRef] = useState(null)

    const handleClick = (e) => {
        setanchorRef(e.currentTarget)
    }

    const handleClose = (event) => {
        setanchorRef(null)
        
        if(event.target.innerText === 'Deactivate') {

        }
    }

    useEffect(() => {
       props.fetchUserData()
       
    },[])


    const rolebaseduseraccess = () => {
        const { user } = JSON.parse(localStorage.getItem("user"))
        if(user.role === 'Super Admin' || user.role === 'Deviare Organisation Admin'){
            return(
                <TableComponent 
                    cols={[
                        {field:'id',headerName:'Sl.No',flex:1},
                        {field:'email',headerName:'Email',flex:1},
                        {field:'role',headerName:'Role',flex:1},
                        {field:'first_name',headerName:'First Name',flex:1},
                        {field:'last_name',headerName:'Last Name',flex:1},
                        {field:'organization_name',headerName:'Organization',flex:1},
                        {field:'status',headerName:'Status',renderCell:(value) => {
                            if(value.row.status === 'Active'){
                                return(
                                    <EditStatusComponent
                                        status={value.row.status}
                                        statuscolor='#32CD32'
                                        showDropDown={false}/>
                                )
                            }
                            else {
                                return(
                                    <EditStatusComponent
                                        status={value.row.status}
                                        statuscolor='red'
                                        showDropDown={false}/> 
                                )
                            }
                        }},
                        {
                            field:'edit',
                            headerName:'Edit',
                            sortable:false,
                            disableClickEventBubbling:true,
                            renderCell:(value) => {
                                return (
                                    <IconButton 
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}>
                                        <MoreVert/>
                                    </IconButton>
                                )
                            }
                        }
                    ]}
                    rows={props.user.userlist}
                    filter={{
                        items:[
                            {
                                columnField:'', operatorValue:'',value:''
                            }
                        ]
                    }}/>
            )
        }
        else {
            return(
                <TableComponent 
                    cols={[
                        {field:'id',headerName:'Sl.No',flex:1},
                        {field:'email',headerName:'Email',flex:1},
                        {field:'role',headerName:'Role',flex:1},
                        {field:'first_name',headerName:'First Name',flex:1},
                        {field:'last_name',headerName:'Last Name',flex:1},
                        {field:'organization_name',headerName:'Organization',flex:1},
                        {field:'status',headerName:'Status',renderCell:(value) => {
                            if(value.row.status === 'Active'){
                                return(
                                    <EditStatusComponent
                                        status={value.row.status}
                                        statuscolor='#32CD32'/>
                                )
                            }
                            else {
                                return(
                                    <EditStatusComponent
                                        status={value.row.status}
                                        statuscolor='red'/> 
                                )
                            }
                        }},
                        {
                            field:'action',
                            headerName:'Edit',
                            sortable:false,
                            disableClickEventBubbling:true,
                            renderCell:(value) => {
                                return (
                                    <IconButton 
                                        aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick}>
                                        <MoreVert/>
                                    </IconButton>
                                )
                            }
                        }
                    ]}
                    rows={props.user.userlist.filter((x) => x.organization === props.id)}
                    filter={{
                        items:[
                            {
                                columnField:'', operatorValue:'',value:''
                            }
                        ]
                    }}/>
            )
        }
    }
    const classes = useStyles();
     
    return(
        <div>
            <Typography variant="h1" noWrap className={classes.typo}>User Management</Typography>
            {rolebaseduseraccess()}
            <Menu 
                id="simple-menu"
                anchorEl={anchorRef}
                keepMounted
                open={Boolean(anchorRef)}
                onClose={handleClose}>
                    <MenuItem value="Deactivate" onClick={handleClose}>Deactivate</MenuItem>        
            </Menu>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user:state.user
    }
}

const mapDispatchToProps = dispatch => ({
    fetchUserData:() => {
        dispatch(fetchUser())
    }
})

UserComponent.propTypes = {
    user:PropTypes.object,
    fetchUserData:PropTypes.func,
    id:PropTypes.string,
}

export default connect(mapStateToProps,mapDispatchToProps)(UserComponent)