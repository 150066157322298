import React from 'react';
import { Dialog, DialogContent, DialogTitle, DialogActions, IconButton, Button, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root:{
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
    }
}))

const Modals = ({ handleClose, show, children, onPress, title, showSubmit,size }) => {
    const classes = useStyles();

    return (
        <Dialog
         open={show}
         onClose={handleClose}
         scroll='paper'
         aria-labelledby="scroll-dialog-title"
         aria-describedby="scroll-dialog-description"
         maxWidth={size}>
            <DialogTitle id="customized-dialog-title" disableTypography style={{background:'#143174'}}>
                <div style={{display:'flex', justifyContent:'space-between'}}>
                    <Typography variant="h3" noWrap style={{color:'white',marginTop:'12px'}}>{title}</Typography>
                    <IconButton aria-label="close" onClick={handleClose}><Close style={{color:'white'}}/></IconButton>
                </div>   
            </DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            {showSubmit ? <DialogActions>
                <Button autoFocus onClick={onPress} color="primary">
                    Submit
                </Button>
            </DialogActions>: null}
        </Dialog>
    );
}

Modals.propTypes = {
    handleClose:PropTypes.func,
    show:PropTypes.bool,
    children:PropTypes.element.isRequired,
    onPress:PropTypes.func,
    title:PropTypes.string,
    showSubmit:PropTypes.bool,
    size:PropTypes.string
}

export default Modals