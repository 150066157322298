import React,{ Component } from 'react';
import { Grid, Card, Button, MenuItem, CardHeader, CardMedia, CardContent, CardActions, Avatar, Typography, Dialog, DialogTitle ,DialogContent, DialogContentText, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { FilterList, Cancel } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import DropDownComponent from './Components/DropDownComponent';
import { fetchSandbox, postLab, fetchImage, fetchLab, upgardeSandbox, sendLabRequestmail } from './app/ActionCreator';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import launch from './assests/launch.png';
import { baseUrl } from './shared/baseUrl'; 

const styles = {
    root:{
        marginTop:'60px',
    },
    buttonColor:{
        color:'white',
        background:'#143174',
        '&hover':{
            color:'white',
            background:'#143174'  
        }
    },
    
    filterDiv:{
        border:'none',
        background:'transparent',
        marginTop:'30px'
    },
    cardRoot:{
        maxWidth:345,
        background:'white'
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    avatar: {
        background:'red'
    }
}

class LearningLabs extends Component{
    constructor(props){
        super(props)
        this.state = {
            selectedTitle:'A7L 5P7',
            selectedImages:'dummyimage.com/209x100.png',
            showFilterDiv:false,
            showModal:false,
            showerror:false,
            sandobj:{},
            showlabrequestsuccess:false,
            showlabrequesterror:false
        }
    }


    handleChangeTitle = (event) => {
        this.setState({selectedTitle:event.target.value})
    }
    handleChangeImage = (event) => {
        this.setState({selectedImages:event.target.value})
    }

    

    componentDidMount(){
        this.props.fetchSandboxData()
        this.props.fetchImageData()
        this.props.fetchLabData()
    }

 

    componentDidUpdate(props,state){
        if(props.lab.laberror !== this.props.lab.laberror){
            this.setState({showerror:true})  
        }
        if(props.lab.labadded !== this.props.lab.labadded && this.props.lab.labadded !== null ){
            this.setState({showModal:true})
            const formData1 = new FormData()
            formData1.append("title",this.state.sandobj.title)
            formData1.append("description",this.state.sandobj.description)
            formData1.append("instance",this.state.sandobj.instance)
            formData1.append("createdBy",this.state.sandobj.createdBy)
            formData1.append("status",this.state.sandobj.status)
            formData1.append("serverid",this.props && this.props.lab && this.props.lab.labadded && this.props.lab.labadded.name)
            formData1.append("type","Lab")
            formData1.append("duration",this.state.sandobj.duration)
            this.props.upgardeSandboxData(this.state.sandobj._id,formData1)
            this.props.fetchLabData()
        }
        if(props.emailsend.emailsend !== this.props.emailsend.emailsend){
            this.setState({showlabrequestsuccess:true})
        }
        if(props.emailsend.emailsenderror !== this.props.emailsend.emailsenderror){
            this.setState({showlabrequesterror:true})
        }
       
    }

    lauchlab = (obj) => {
        const inage = this.props.Images.images.filter((x) => x.instance_name === obj.instance)
        if(inage.length > 0){
            const data = {
                "name":obj.title,
                "lab_name":obj.title,
                "instance":inage[0].id,
                "lifetime":obj.duration,
                "lab_type":"lab"
            }
            this.props.postLabData(data)
            this.setState({sandobj:obj})
        }
           
    }

    requesttoacess = () => {
        this.props.sendLabRequestmailData(this.state.sandobj.title)
        this.setState({showerror:false})
    }

    errorsnackbar = () => {
        if(this.props.emailsend.emailsenderror !== null){
            return (
                <Snackbar
                    open={this.state.showlabrequesterror}
                    autoHideDuration={3000}
                    onClose={() => this.setState({showlabrequesterror:false})}>
                    <Alert severity="error">{this.props.emailsend.emailsenderror}</Alert>    
                </Snackbar>
            )
        }
        else {
            return null
        }
        
    }

    successsnackbar = () => {
        if(this.props.emailsend.emailsend !== null){
            return (
                <Snackbar
                    open={this.state.showlabrequestsuccess}
                    autoHideDuration={3000}
                    onClose={() => this.setState({showlabrequestsuccess:false})}>
                    <Alert severity="success">{this.props.emailsend.emailsend}</Alert>    
                </Snackbar>
            )
        }
        else {
            return null
        }
        
    }

    displayError = () => {
        if(this.props.lab.laberror !== null){
        return(
               <Dialog
                fullWidth={true}
                maxWidth={"xs"}
                open={this.state.showerror}
                onClose={() => this.setState({showerror:false})}
                aria-labelledby="max-width-dialog-title">
                <DialogTitle id="error-lab-title">
                    <Cancel style={{height:200,width:200,color:'red',display:'block',marginLeft:'auto',marginRight:'auto'}}/>
                    <Typography style={{flexGrow:1,textAlign:'center',fontWeight:'bold',color:'black',fontSize:15}}>Sorry!</Typography>
                </DialogTitle>    
                <DialogContent>
                    <DialogContentText style={{textAlign:'center',fontWeight:'500',fontSize:15,color:'black'}}>You do not Have access to launch this lab </DialogContentText>
                    <Button color="primary" style={{display:'block',marginLeft:'auto',marginRight:'auto'}} onClick={this.requesttoacess}>Send A Request To access this lab </Button>
                    <Button color="primary" style={{display:'block',marginLeft:'auto',marginRight:'auto'}} onClick={() => this.setState({showerror:false})}>Cancel</Button>
                </DialogContent>   
               </Dialog> 
            )
        }
        else {
            return null;
        }
    }

    displaylaunchsuccess = () => {
        if (this.state.showModal){
            return (
                <Dialog
                 fullWidth={true}
                 maxWidth={"xs"}
                 open={this.state.showModal}
                 onClose={() => this.setState({showModal:false})}
                 aria-labelledby="max-width-dialog-title">
                 <DialogContent>
                    <img src={launch} style={{display:'block',margin:'auto',height:100,width:100}}/>
                    <DialogContentText style={{textAlign:'center',color:'black',fontWeight:'500',margin:10}}>Launch Successfull</DialogContentText>
                 </DialogContent>
                </Dialog>
            )
        }
        else {
            return null
        }

    }
    

    render(){
        const { classes } = this.props
        const { user } = JSON.parse(localStorage.getItem("user"))
        return(
            <div className={classes.root}>
                 
               <Button variant='contained' startIcon={<FilterList/>} onClick={() => this.setState({showFilterDiv:!this.state.showFilterDiv})} className={classes.buttonColor}> Filters and Tags </Button>
                {
                   this.state.showFilterDiv ? 
                       <Grid container direction="row" justify="space-around" alignItems="flex-start">
                                <DropDownComponent
                                    dropdownid="standard-select-title"
                                    selectedValue={this.state.selectedTitle}
                                    handleChange={this.handleChangeTitle}
                                    dropdownLabel="Select Title">
                                    <MenuItem value="A7L 5P7">A7L 5P7</MenuItem>
                                    <MenuItem value="Q6P 5Y7">Q6P 5Y7</MenuItem>
                                    <MenuItem value="S7R 0UR">S7R 0UR</MenuItem>
                                </DropDownComponent>
                                <DropDownComponent
                                    dropdownid="standard-select-image"
                                    selectedValue={this.state.selectedImages}
                                    handleChange={this.handleChangeImage}
                                    dropdownLabel="Select Sandbox Images">
                                    <MenuItem value="dummyimage.com/209x100.png">dummyimage.com/209x100.png</MenuItem>
                                    <MenuItem value="dummyimage.com/213x100.png">dummyimage.com/213x100.png</MenuItem>
                                    <MenuItem value="dummyimage.com/120x100.png">dummyimage.com/120x100.png`</MenuItem>      
                                </DropDownComponent>    
                        </Grid> : null 
                }
                <Grid container direction="row" justify="flex-start" alignItems="flex-start" >
                    { this.props.sandbox.sandbox.filter((x) => x.type === "Lab" && x.status === "Published").map((v,i) => {
                        const img = `${baseUrl}uploads/${v.mappedimage}`
                        const labdata = this.props.lab.labs.some((x) => x.lab_name === v.title && x.created_by.id === user.id)
                        return(
                            <div key={v._id} style={{margin:20}}>
                                <Card className={classes.cardRoot}>
                                    <CardHeader 
                                        avatar={<Avatar aria-label="receipe" className={classes.avatar}>{v.createdBy.charAt(0)}</Avatar>}
                                        title={v.title}
                                        subheader={v.createdAt}/>
                                    <CardMedia
                                        className={classes.media}
                                        image={img}
                                        title="Contemplative Reptile"/>  
                                    <CardContent>
                                    <Typography variant="body2">
                                    {v.description}
                                    </Typography>
                                    </CardContent>
                                    <CardActions>
                                       
                                        { labdata ? <Link to={`/lab/${v._id}`} style={{textDecoration:'none'}}><Button className={classes.buttonColor}>VIEW DETAILS</Button></Link> :  <Button className={classes.buttonColor} onClick={() => this.lauchlab(v)}>LAUNCH</Button>}
                                    </CardActions>                
                                </Card>    
                            </div> 
                        )
                    })}
                </Grid>
                {this.displaylaunchsuccess()}
                {this.displayError()}
                {this.successsnackbar()}
                {this.errorsnackbar()}      
            </div>    
        )
    }
}
const mapStateToProps = state => {
    return{
        sandbox:state.sandbox,
        Images:state.Images,
        lab:state.lab,
        emailsend:state.sendemail,
    }
}

const mapDispatchToProps = dispatch => ({
    fetchSandboxData:() => {
        dispatch(fetchSandbox())
    },
    postLabData:(data) => {
        dispatch(postLab(data))
    },
    fetchImageData:() => {
        dispatch(fetchImage())
    },
    fetchLabData:() => {
        dispatch(fetchLab())
    },
    upgardeSandboxData:(id,data) => {
        dispatch(upgardeSandbox(id,data))
    },
    sendLabRequestmailData:(labname) => {
        dispatch(sendLabRequestmail(labname))
    }
})

LearningLabs.propTypes = {
    classes:PropTypes.object.isRequired,
    sandbox:PropTypes.object,
    fetchSandboxData:PropTypes.func,
    postLabData:PropTypes.func,
    Images:PropTypes.object,
    fetchImageData:PropTypes.func,
    lab:PropTypes.object,
    fetchLabData:PropTypes.func,
    upgardeSandboxData:PropTypes.func,
    sendLabRequestmailData:PropTypes.func,
    emailsend:PropTypes.object
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(LearningLabs));