export const AUTH_LOGIN_SUCCESS = "AUTH_LOGIN_SUCCESS"
export const AUTH_LOGIN_ERROR = "AUTH_LOGIN_ERROR"
export const AUTH_LOGIN_LOADING = "AUTH_LOGIN_LOADING"
export const AUTH_LOGOUT = "AUTH_LOGOUT" 
export const SANDBOX_LOADING = "SANDBOX_LOADING"
export const GET_SANDBOX = "GET_SANDBOX"
export const GET_SANDBOX_BY_ID = "GET_SANDBOX_BY_ID"
export const SANDBOX_FAILED = "SANDBOX_FAILED"
export const IMAGE_LOADING = "IMAGE_LOADING"
export const GET_IMAGE = "GET_IMAGE"
export const IMAGE_FAILED = "IMAGE_FAILED"
export const SECTION_LOADING = "SECTION_LOADING"
export const GET_SECTION = "GET_SECTION"
export const DELETE_SECTION = "DELETE_SECTION"
export const SECTION_FAILED = "SECTION_FAILED"
export const SUBSECTION_LOADING = "SUBSECTION_LOADING"
export const GET_SUBSECTION = "GET_SUBSECTION"
export const SUBSECTION_FAILED = "SUBSECTION_FAILED"
export const EXERCISE_LOADING = "EXERCISE_LOADING"
export const GET_EXERCISE = "GET_EXERCISE"
export const EXERCISE_FAILED = "EXERCISE_FAILED"
export const GET_CODE = "GET_CODE"
export const CODE_LOADING = "CODE_LOADING"
export const CODE_FAIL = "CODE_FAIL"
export const ADD_LAB = "ADD_LAB"
export const GET_LAB = "GET_LAB"
export const GET_LAB_BY_ID = "GET_LAB_BY_ID"
export const DELETE_LAB = "DELETE_LAB"
export const UPDATE_LAB = "UPDATE_LAB"
export const LAB_INSTANCES = "LAB_INSTANCES"
export const LAB_LOADING = "LAB_LOADING"
export const LAB_ERROR = "LAB_ERROR"
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS"
export const SEND_EMAIL_LOADING = "SEND_EMAIL_LOADING"
export const SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED"
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED"
export const RESET_PASSWORD_LOADING = "RESET_PASSWORD_LOADING"
export const CREATE_USER = "CREATE_USER"
export const GET_USERS = "GET_USERS"
export const USER_LOADING = "USER_LOADING"
export const USER_ERROR = "USER_ERROR"
export const GET_PROFILES = "GET_PROFILES"
export const CREATE_PROFILE = "CREATE_PROFILE"
export const PROFILE_LOADING = "PROFILE_LOADING"
export const PROFILE_ERROR = "PROFILE_ERROR"
export const GET_ORGANIZATION = "GET_ORGANIZATION"
export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION"
export const GET_ORGANIZATION_BY_ID = "GET_ORGANIZATION_BY_ID"
export const ORGANIZATION_LOADING = "ORGANIZATION_LOADING"
export const ORGANIZATION_ERROR = "ORGANIZATION_ERROR"