import * as ActionTypes from './ActionTypes';

const initialState = {
    isLoading:false,
    emailsend:null,
    emailsenderror:null
}

export const SendEmail = (state = initialState,action) => {
    switch(action.type){
        case ActionTypes.SEND_EMAIL_SUCCESS:
            return {
                ...state,
                isLoading:false,
                emailsend:action.payload,
                emailsenderror:null
            }
        case ActionTypes.SEND_EMAIL_LOADING:
            return {
                ...state,
                isLoading:true,
                emailsend:null,
                emailsenderror:null
            }
        case ActionTypes.SEND_EMAIL_FAILED:
            return {
                ...state,
                isLoading:false,
                emailsend:null,
                emailsenderror:action.payload
            }
        default:
            return state;            
    }
}