import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Snackbar } from '@material-ui/core';
import Modals from './Modals';
import { Alert } from '@material-ui/lab';
import { postUser, fetchProfile, fetchOrganization  } from '../app/ActionCreator';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';



function CreateUserComponent(props){
    const [showsnackbar,setshowsnackbar] = useState(false)
    const [showModal,setshowModal] = useState(true)
    const [email,setEmail] = useState('')
    const [firstname,setfirstname] = useState('')
    const [lastname,setlastname] = useState('')
    const [password,setpassword] = useState('')
    const [role,setrole] = useState('')
    const [profileid,setprofileid] = useState('')
    const [profile,setprofile] = useState('')
    const [orgid,setorgid] = useState('')
    const [orgname,setorgname] = useState('')

    useEffect(() => {
        props.fetchProfileData()
        props.fetchOrganizationData()
        const { user } = JSON.parse(localStorage.getItem("user"))
        const organizationid = user.organization
        setorgid(organizationid)
        
    },[])

    const createuser = () => {
     
        const createuserdata = new FormData()
        createuserdata.append("email",email)
        createuserdata.append("role",role)
        createuserdata.append("first_name",firstname)
        createuserdata.append("last_name",lastname)
        createuserdata.append("password",password)
        createuserdata.append("organization",orgid)
        createuserdata.append("profile",profile)

        props.postUserData(createuserdata,profileid)
        setshowModal(false)
        setshowsnackbar(true)
        

    }

    const handleorgid = (e) => {
        const org = props.organization.organizationList.filter(x  => x.name === e.target.value)
        setorgname(e.target.value)
        if(org.length > 0){
            setorgid(org[0].id)
        }
        
    }
    
    const handleprofile = (e) => {
        const profiledata = props.profile.profiles.filter((x) => x.name === e.target.value)
        setprofileid(profiledata[0].id)
        setprofile(e.target.value)
    } 

     const roleBasedUser = () => {
        const { user } = JSON.parse(localStorage.getItem("user"))
        if(user.role === 'Organisation Admin'){
            return(
                <TextField 
                    id="role"
                    select
                    label="Role"
                    fullWidth
                    style={{margin:8}}
                    margin="normal"
                    onChange={(e) => setrole(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}>
                    <MenuItem value="Organisation Admin">Organisation Admin</MenuItem>
                    <MenuItem value="Organisation User">Organisation User</MenuItem>
                </TextField> 
            )
            
        }
        if(user.role === 'Deviare Organisation Admin'){
            return(
                <TextField 
                    id="role"
                    select
                    label="Role"
                    fullWidth
                    style={{margin:8}}
                    margin="normal"
                    onChange={(e) => setrole(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}>
                    <MenuItem value="Deviare Organisation Admin">Deviare Organisation Admin</MenuItem>
                    <MenuItem value="Template User">Template User</MenuItem>
                    <MenuItem value="Lab Creator">Lab Creator</MenuItem>    
                    <MenuItem value="Organisation Admin">Organisation Admin</MenuItem>
                    <MenuItem value="Organisation User">Organisation User</MenuItem>
                </TextField> 
            )  
        }
        return(
            <TextField 
                id="role"
                select
                label="Role"
                fullWidth
                style={{margin:8}}
                margin="normal"
                onChange={(e) => setrole(e.target.value)}
                InputLabelProps={{
                    shrink: true,
                }}>
                <MenuItem value="Super Admin">Super Admin</MenuItem>
                <MenuItem value="Deviare Organisation Admin">Deviare Organisation Admin</MenuItem>
                <MenuItem value="Template User">Template User</MenuItem>
                <MenuItem value="Lab Creator">Lab Creator</MenuItem>
                <MenuItem value="Organisation Admin">Organisation Admin</MenuItem>
                <MenuItem value="Organisation User">Organisation User</MenuItem>        
            </TextField>
        )
    }

    const rolebasedOrganisationName = () => {
        const { user } = JSON.parse(localStorage.getItem("user"))
        if(user.role === 'Super Admin' || user.role === 'Deviare Organisation Admin'){
            if(role === 'Organisation Admin'){
                return (
                    <TextField 
                        id="organisation"
                        select
                        label="Organisation Name"
                        fullWidth
                        style={{margin:8}}
                        margin="normal"
                        onChange={handleorgid}
                        InputLabelProps={{
                            shrink: true,
                        }}>
                        {
                            props.organization.organizationList.map((v,i) => {
                                return(
                                    <MenuItem value={v.name} key={v.id}>{v.name}</MenuItem>
                                )
                            })
                        }  
                    </TextField>
                )
            }
            else {
                return null
            }
        }
        else {
            return null
        }

    }

    const { user } = JSON.parse(localStorage.getItem("user"))
    return(
        <div>
        <Modals
            show={showModal}
            title="Create User"
            showSubmit={true}
            handleClose={() => setshowModal(false)}
            onPress={createuser}
            size="xs">
            <div>
                <TextField 
                    id="firstname"
                    label="First Name"
                    style={{margin: 8}}
                    placeholder="Enter First Name"
                    fullWidth
                    onChange={(e) => setfirstname(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }} />
                <TextField 
                    id="lastname"
                    label="Last Name"
                    style={{margin: 8}}
                    placeholder="Enter Last Name"
                    fullWidth
                    onChange={(e) => setlastname(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }} />
                <TextField 
                    id="emailid"
                    label="EmailID"
                    style={{margin: 8}}
                    placeholder="Enter Email ID"
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }} />
                <TextField 
                    id="password"
                    label="Password"
                    style={{margin: 8}}
                    placeholder="Enter Password"
                    fullWidth
                    type="password"
                    onChange={(e) => setpassword(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }} />    
                {roleBasedUser()}
                {
                    role === 'Organisation User' ? 
                        <TextField
                            id="profile"
                            select
                            label="Profile Name"
                            fullWidth
                            style={{margin:8}}
                            margin="normal"
                            onChange={handleprofile}
                            InputLabelProps={{
                                shrink: true,
                            }}>
                            {
                                props.profile.profiles.filter(x => x.organization === user.organization).map((v,i) => {
                                    return(
                                        <MenuItem key={v.id} value={v.name}>{v.name}</MenuItem>
                                    )    
                            })}    
                        </TextField>
                        :null
                }
                {rolebasedOrganisationName()}     
            </div>    
        </Modals>
        {props.user && props.user.userlist.length > 0 ? <Snackbar open={showsnackbar} autoHideDuration={3000} onClose={() => setshowsnackbar(false)} anchorOrigin={{vertical:'top',horizontal:'right'}}>
            <Alert severity="success"> User Added Successfully </Alert>
        </Snackbar> : null}
        {props.user && props.user.usererr !== null ? <Snackbar open={showsnackbar} autoHideDuration={3000} onClose={() => setshowsnackbar(false)} anchorOrigin={{vertical:'top',horizontal:'right'}}>
            <Alert severity="error"> Sorry please try again </Alert>
        </Snackbar> : null}
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user:state.user,
        profile:state.profile,
        organization:state.organization,
    }
}

const mapDispatchToProps = dispatch => ({
    postUserData:(data,id) => {
        dispatch(postUser(data,id))
    },
    fetchProfileData:() => {
        dispatch(fetchProfile())
    },
    fetchOrganizationData:() => {
        dispatch(fetchOrganization())
    },
    
})

CreateUserComponent.propTypes = {
    postUserData:PropTypes.func,
    user:PropTypes.object,
    fetchProfileData:PropTypes.func,
    fetchOrganizationData:PropTypes.func,
    profile:PropTypes.object,
    organization:PropTypes.object,
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateUserComponent);