import * as ActionTypes from './ActionTypes';

const initialState = {
    isLoading:false,
    labs:[],
    labinstances:[],
    labDetail:{},
    laberror:null,
    labadded:null,
}

export const Lab=(state=initialState,action) => {
    switch(action.type){
        case ActionTypes.ADD_LAB:
            return {
                ...state,
                labs:[],
                isLoading:false,
                labDetail:{},
                laberror:null,
                labadded:action.payload,
            }
        case ActionTypes.GET_LAB:
            return {
                ...state,
                labs:action.payload,
                isLoading:false,
                labDetail:{},
                laberror:null,
                labadded:null
            }
 
        case ActionTypes.LAB_LOADING:
            return {
                ...state,
                labs:[],
                isLoading:true,
                labDetail:{},
                laberror:null,
                labadded:null,
            }
        case ActionTypes.GET_LAB_BY_ID:
            return {
                ...state,
                labs:[],
                isLoading:false,
                labDetail:action.payload,
                laberror:null,
                labadded:null,
            }
               
        case ActionTypes.LAB_ERROR:
            return {
                ...state,
                labs:[],
                isLoading:false,
                labDetail:{},
                laberror:action.payload,
                labadded:null,
                labinstances:[]
            }
        case ActionTypes.LAB_INSTANCES:
            return {
                ...state,
                labinstances:action.payload,
                labs:[],
                isLoading:false,
                laberror:null,
                labadded:null
            }    
        default:
            return state             
    }
}