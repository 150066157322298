import React,{ useEffect, useState } from 'react';
import { Menu, MenuItem, Link, Typography, IconButton, List, ListItemText, ListItem } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import Modals from './Modals';
import TableComponent from './TableComponent';
import { connect } from 'react-redux';
import { fetchProfile } from '../app/ActionCreator';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    typo:{
        flexGrow:1,
        textAlign:'center',
        marginTop:'30px',
        color:'black'
    },
    headingpaper:{
        width:'100%',
        height:40,
        marginTop:'30px',
        background:'white',
        borderColor:'white',
    },
}))

function RolesComponent(props){
    const [showModal,setShowModal] = useState(false)
    const [users,setusers] = useState([])
    const [anchorEl,setanchorEl] = useState(null)

    const handleClick = (e) => {
        setanchorEl(e.currentTarget)
    }

    const handleClose = (event) => {
        setanchorEl(null)
        if(event.target.innerText === 'Deactivate') {

        }
    }

   useEffect(() => {
    props.fetchProfileData()
   },[])

    const classes = useStyles()

    const openModal = (user) => {
        setShowModal(true)
        setusers(user)
    }

    const rolebasedprofileaccess = () => {
        const { user } = JSON.parse(localStorage.getItem("user"))
        if(user.role === 'Super Admin' || user.role === 'Deviare Organisation Admin'){
            return(
              <TableComponent
                cols={[
                    {field:'id',headerName:'Sl.No',flex:1},
                    {field:'name',headerName:'Profile Name',flex:1},
                    {field:'created_by.organization_name',headerName:'Organisation',flex:1,renderCell:(params) => {
                        if(params.row && params.row.created_by && params.row.created_by.organization_name !== undefined){
                            return (
                                <Typography>{params.row.created_by.organization_name}</Typography>
                            )
                        }
                        else {
                            return (
                                
                                <Typography></Typography>
                            )
                        }   
                    }},
                    {
                        field:'user',
                        headerName:'Users Attached',
                        sortable:false,
                        flex:1,
                        disableClickEventBubbling:true,
                        renderCell:(params) => {
                            return(
                                <Link component={Typography} variant="body2" onClick={() => openModal(params.row.user)}>{params.row.user.length}</Link>
                            )
                        }
                    },
                    {
                        field:'created_at',headerName:'createdAt',flex:1
                    },
                    {field:'created_by.first_name',headerName:'CreatedBy',flex:1,renderCell:(params) => {
                        if(params.row && params.row.created_by && params.row.created_by.first_name !== undefined){
                            return (
                                <Typography>{params.row.created_by.first_name + params.row.created_by.last_name}</Typography>
                            )    
                        }
                        else {
                            return (
                                
                                <Typography></Typography>
                            )
                            
                        }   
                    }},
                    {
                        field:'action',
                        headerName:'Edit',
                        sortable:false,
                        flex:1,
                        disableClickEventBubbling:true,
                        renderCell:(params) => {
                            return(
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}>
                                    <MoreVert/>
                                </IconButton>
                            )
                        }
                    }

                ]}
                rows={props.profile.profiles}
                filter={{
                    items:[
                        {
                            columnField:'',
                            operatorValue:'',
                            value:''
                        }
                    ]
                }}/>
            )
        }
        else {
            return(
                <TableComponent
                cols={[
                    {field:'id',headerName:'Sl.No',flex:1},
                    {field:'name',headerName:'Profile Name',flex:1},
                    {field:'created_by.organization_name',headerName:'Organisation',flex:1,renderCell:(params) => {
                        if(params.row && params.row.created_by && params.row.created_by.organization_name !== undefined){
                            return (
                                <Typography>{params.row.created_by.organization_name}</Typography> 
                            )
                        }
                        else {
                            return (
                                
                                <Typography></Typography>
                            )
                        }   
                    }},
                    {
                        field:'user',
                        headerName:'Users Attached',
                        sortable:false,
                        flex:1,
                        disableClickEventBubbling:true,
                        renderCell:(params) => {
                            return(
                                <Link component={Typography} variant="body2" onClick={() => openModal(params.row.user)}>{params.row.user.length}</Link>
                            )
                        }
                    },
                    {
                        field:'created_at',headerName:'createdAt',flex:1
                    },
                    {field:'created_by.first_name',headerName:'CreatedBy',flex:1,renderCell:(params) => {
                        if(params.row && params.row.created_by && params.row.created_by.first_name !== undefined){
                            return (
                                <Typography>{params.row.created_by.first_name + params.row.created_by.last_name}</Typography>  
                            )
                        }
                        else {
                            return (
                               
                                <Typography></Typography>
                            )
                        }   
                    }},
                    {
                        field:'edit',
                        headerName:'Edit',
                        sortable:false,
                        flex:1,
                        disableClickEventBubbling:true,
                        renderCell:(params) => {
                            return(
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={handleClick}>
                                    <MoreVert/>
                                </IconButton>
                            )
                        }
                    }

                ]}
                rows={props.profile.profiles.filter(x => x.organization === user.organization)}
                filter={{
                    items:[
                        {
                            columnField:'',
                            operatorValue:'',
                            value:''
                        }
                    ]
                }}/>
            )
        }
    }

    return(
        <div>
            <Typography variant="h1" noWrap className={classes.typo}>User Profiles</Typography>
            {rolebasedprofileaccess()}
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                size="xs">
                    <MenuItem value="Deactivate" onClick={handleClose}>Deactivate</MenuItem> 
            </Menu> 
            <Modals
                show={showModal}
                title="List of Users"
                handleClose={() => setShowModal(false)}
                onPress={() => setShowModal(false)}>
                <div>
                    <List component="nav" aria-label="secondary user folder">
                        {
                            users.map((v,i) => {
                                return(
                                    <ListItem key={i} button>
                                        <ListItemText primary={v.first_name + v.last_name}/>   
                                    </ListItem>
                                )
                            })
                        }  
                    </List> 
                </div> 
            </Modals> 
        </div> 
    )
}

const mapStateToProps = state => {
    return {
        profile:state.profile
    }
}

const mapDispatchToProps = dispatch => ({
    fetchProfileData:() => {
        dispatch(fetchProfile())
    }
})

RolesComponent.propTypes = {
    profile:PropTypes.object,
    fetchProfileData:PropTypes.func
}

export default connect(mapStateToProps,mapDispatchToProps)(RolesComponent)