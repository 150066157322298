import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Auth } from './Auth';
import { Section } from './Section';
import { subSection } from './SubSection';
import { sandbox } from './sandbox';
import { Images } from './Images';
import { exercise } from './Exercise';
import { codeEditor } from './CodeEditor';
import { Lab } from './Lab'
import { SendEmail } from './SendEmail';
import { ResetPassword } from './ResetPassword';
import { User } from './User';
import { Profiles } from './Profiles';
import { organization } from './organization';
import thunk from 'redux-thunk';
import logger from 'redux-logger';


let middleware = process.env.NODE_ENV === 'production' ? [thunk] : [thunk,logger]

export const configureStore = () => {
  const store = createStore(
    combineReducers({
      auth:Auth,
      sendemail:SendEmail,
      resetpassword:ResetPassword,
      lab:Lab,
      section:Section,
      subsection:subSection,
      exercise:exercise,
      sandbox:sandbox,
      Images:Images,
      code:codeEditor,
      user:User,
      profile:Profiles,
      organization:organization
    }), 
    applyMiddleware(...middleware)
    // +  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
  return store;
}
