import React, { Component } from 'react';
import Dashboard from './Dashboard';
import Login from './Login';
import LearningLabs from './LearningLabs';
import MyLearningLabs from './MyLearningLabs';
import Sandboxes from './Sandboxes';
import MySandBoxes from './MySandboxes';
import Profile from './Profile';
import Users from './Users';
import Roles from './Roles';
import SandboxDetail from './SandboxDetail';
import LabDetail from './LabDetail';
import AdminLearningLab from './AdminLearningLab';
import AdminSandbox from './AdminSandBox';
import AdminImages from './AdminImages';
import CreateSandbox from './CreateSandbox';
import CreateLab from './CreateLab';
import { Route, Switch, Link, NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { Drawer, AppBar, CssBaseline, Toolbar, List, ListItem, ListItemText, Collapse, Grid, Typography, Avatar, Menu, MenuItem, Divider, IconButton } from '@material-ui/core';
import { ExpandLess, ExpandMore, ChevronLeft, ChevronRight, Person } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import transitions from '@material-ui/core/styles/transitions';
import draweritem from './shared/drawerItems.json';
import logo from './deviare-logo-light.svg';
import PropTypes from 'prop-types';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import Organization from './Organization';
import CreateOrganization from './CreateOrganization';
import { doLogout } from './app/ActionCreator';
import { connect } from 'react-redux';

const styles = {
  root:{
    display:'flex'
  },
  appBar:{
    transition:transitions.create(['margin','width'],{
      easing:transitions.easing.sharp,
      duration:transitions.duration.leavingScreen
    }),
    background:'white',
  },
  menuButton:{
    marginRight: '16px'
  },
  hide:{
    display:'none'
  },
  drawer: {
    width: 250,
    flexShrink: 0
  },
  drawerPaper: {
    width: 250,
    backgroundColor:'#143174',
    marginTop:65
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0px 8px',
    minHeight:56,
    justifyContent: 'flex-end',
  },
  avatarColor:{
    background:'#B0C4DE',
    marginTop:'10px',
    opacity:'0.5'
  },
  imgHeight:{
    width:130,
    height:60,
    marginLeft:'5px'
  },
  list: {
    width: 250,
    '&hover':{
      background:'#143174 !important'
    }
  },
  
  title:{
    color:'black',
    textAlign:'center'
  },
  
  activelist:{
    width: 250,
    background:'#2881c7 !important'
  },
  
  
  menu:{
    marginTop:40
  },
  
  listtextColor:{
    color:'white'
  },
  
  links: {
    textDecoration:'none',
  },
    
  iconColor:{
    color:'white'
  },
  content: {
    flexGrow: 1,
    padding: '24px',
    transition: transitions.create('margin', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
    marginLeft: -250,
  },
  contentShift: {
    transition: transitions.create('margin', {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }
}

class Main extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openDrawer:true,
      anchorEl:null,
      path:"/dashboard",
      id:'',
      role:''
    }
  }
  handleChildClick = (item) => {
    this.setState({path:item})    
    
  }

  handleParentClick = (item,id) => {
    if(item === "/usermanagement"){
      this.setState({path:"/users"})

    }
    else if (item === "/admin") {
      this.setState({path:"/adminlearninglab"})
    }
    else{
      this.setState({path:item})
    }
    this.setState({id:id})
  }

  openMenu = (event) => {
    this.setState({anchorEl:event.target})
  }

  closeMenu = () => {
    this.setState({anchorEl:null})
  }

  handleToogleDrawer = () => {
    this.setState({openDrawer:!this.state.openDrawer})
  }

  handleCloseDrawer = () => {
    this.setState({openDrawer:false})
  }

  static getDerivedStateFromProps(prevProps,prevState){
    if(localStorage.getItem("user") !== null){
      const { user } = JSON.parse(localStorage.getItem("user"))
      return {
        role:user.role
      }
    }
    else {
      return { 
        role:''
      } 
    }
  }

  handler = ( children ) => {
    const { classes } = this.props
    if(this.state.role === 'Deviare Organisation Admin' || this.state.role === 'Super Admin'){
      return children.map( ( subOption, i ) => {
        if ( !subOption.children ) {
          return (
            <div key={ subOption.id }>
              <ListItem 
                button 
                className={ clsx(classes.list,classes.links,this.state.path === subOption.url && classes.activelist)}
                selected={this.state.path === subOption.url}
                onClick={() => this.handleChildClick(subOption.url)}
                component={NavLink}
                to={this.state.path}>
               <ListItemText 
                  inset 
                  primary={ subOption.name }
                  className={classes.listtextColor}
                  style={{margin:'10px'}} />
              </ListItem>
            </div>
          )
        }
        return (
          <div key={ subOption.id }>
          <ListItem 
            button
            className={clsx(classes.list,classes.links,this.state.path === subOption.url && classes.activelist)}
            selected={this.state.path === subOption.url}
            onClick={() => this.handleParentClick(subOption.url,subOption.id)}
            component={NavLink}
            to={this.state.path}>
            <ListItemText 
              inset 
              primary={ subOption.name }
              className={classes.listtextColor} />
              { this.state.id === subOption.id ? 
                <ExpandLess className={classes.iconColor}/> :
                <ExpandMore className={classes.iconColor}/>
              }
            </ListItem>
            <Collapse 
              in={ this.state.id === subOption.id } 
              timeout="auto" 
              unmountOnExit>
                { this.handler( subOption.children ) }
            </Collapse>
          </div>
        )})
    }
    else if(this.state.role === 'Organisation Admin')
    {
      
      return children.map( ( subOption, i ) => {
        if(i === 5){
          return;
        }
        else{
          if ( !subOption.children ) {
            return (
              <div key={ subOption.id }>
                <ListItem 
                  button 
                  className={ clsx(classes.list,classes.links,this.state.path === subOption.url && classes.activelist)}
                  selected={this.state.path === subOption.url}
                  onClick={() => this.handleChildClick(subOption.url)}
                  component={NavLink}
                  to={this.state.path}>
                 <ListItemText 
                    inset 
                    primary={ subOption.name }
                    className={classes.listtextColor}
                    style={{margin:'10px'}} />
                </ListItem>
              </div>
            )
          }
          return (
            <div key={ subOption.id }>
            <ListItem 
              button
              className={clsx(classes.list,classes.links,this.state.path === subOption.url && classes.activelist)}
              selected={this.state.path === subOption.url}
              onClick={() => this.handleParentClick(subOption.url,subOption.id)}
              component={NavLink}
              to={this.state.path}>
              <ListItemText 
                inset 
                primary={ subOption.name }
                className={classes.listtextColor} />
                { this.state.id === subOption.id ? 
                  <ExpandLess className={classes.iconColor}/> :
                  <ExpandMore className={classes.iconColor}/>
                }
              </ListItem>
              <Collapse 
                in={ this.state.id === subOption.id } 
                timeout="auto" 
                unmountOnExit>
                  { this.handler( subOption.children ) }
              </Collapse>
            </div>
          ) 
        }
      })  
    }
    else {
      return children.map( ( subOption, i ) => {
        if(i === 4 || i === 5){
          return;
        }
        else{
          if ( !subOption.children ) {
            return (
              <div key={ subOption.id }>
                <ListItem 
                  button 
                  className={ clsx(classes.list,classes.links,this.state.path === subOption.url && classes.activelist)}
                  selected={this.state.path === subOption.url}
                  onClick={() => this.handleChildClick(subOption.url)}
                  component={NavLink}
                  to={this.state.path}>
                 <ListItemText 
                    inset 
                    primary={ subOption.name }
                    className={classes.listtextColor}
                    style={{margin:'10px'}} />
                </ListItem>
              </div>
            )
          }
          return (
            <div key={ subOption.id }>
            <ListItem 
              button
              className={clsx(classes.list,classes.links,this.state.path === subOption.url && classes.activelist)}
              selected={this.state.path === subOption.url}
              onClick={() => this.handleParentClick(subOption.url,subOption.id)}
              component={NavLink}
              to={this.state.path}>
              <ListItemText 
                inset 
                primary={ subOption.name }
                className={classes.listtextColor} />
                { this.state.id === subOption.id ? 
                  <ExpandLess className={classes.iconColor}/> :
                  <ExpandMore className={classes.iconColor}/>
                }
              </ListItem>
              <Collapse 
                in={ this.state.id === subOption.id } 
                timeout="auto" 
                unmountOnExit>
                  { this.handler( subOption.children ) }
              </Collapse>
            </div>
          ) 
        }
      })
    }
    
  }

  
  render(){
    const { classes } = this.props
    return (
      <div>
        <Switch>
          <Route  exact path="/" render={(props) => <Login {...props}/>}/>
          <Route exact path="/forgot-password" render={(props) => <ForgotPassword {...props}/>}/>
          <Route exact path="/reset-password/:token" render={(props) => <ResetPassword {...props}/>}/>
          <div className={classes.root}>
            <CssBaseline/>
            <AppBar 
              positon="fixed"
              className={classes.appBar}>
              <Toolbar>  
              <Grid container direction="row" justify="space-between" alignItems="flex-end" >
                  <img src={logo} alt={logo} className={classes.imgHeight} onClick={this.handleToogleDrawer}/>
                  <Typography variant="h1" noWrap style={{marginTop:'10px',color:'black'}}>Deviare Cloud Platform</Typography>
                  <Avatar aria-controls="simple-menu" aria-haspopup="true" onClick={this.openMenu} className={classes.avatarColor}><Person/></Avatar>
                  <Menu 
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={this.closeMenu}>
                    <Link to={"/profile"} style={{textDecoration:'none',color:'black'}}><MenuItem onClick={this.closeMenu}>My Profile</MenuItem></Link>
                    <MenuItem onClick={this.closeMenu}> Help </MenuItem>
                    <Link to={"/"} style={{textDecoration:'none',color:'black'}}><MenuItem onClick={() => this.props.logout()}>Logout</MenuItem></Link>    
                  </Menu>
              </Grid>
            </Toolbar>    
            </AppBar>
            <Drawer 
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={this.state.openDrawer}
              classes={{
                paper:classes.drawerPaper,
              }}>
              <div className={classes.drawerHeader}>
                <IconButton onClick={this.handleCloseDrawer}>
                  {this.state.openDrawer ? <ChevronLeft className={classes.iconColor}/>:<ChevronRight className={classes.iconColor}/>}
                </IconButton>
              </div>
              <Divider/>
              <List>
                {this.handler(draweritem.data)}
              </List>    
            </Drawer>
            <main className={clsx(classes.content, {
              [classes.contentShift]: this.state.openDrawer,
            })}>
              <Route  exact path="/dashboard" render={(props) => <Dashboard {...props}/>}/>
              <Route  exact path="/learninglabs" render={(props) => <LearningLabs {...props}/>}/>
              <Route  exact path="/mylearninglabs" render={(props) => <MyLearningLabs {...props} />}/>
              <Route  exact path="/lab/:id" render={(props) => <LabDetail {...props}/>}/>
              <Route  exact path="/sandboxes" render={(props) => <Sandboxes {...props}/>}/>
              <Route  exact path="/mysandboxes" render={(props) => <MySandBoxes {...props}/>}/>
              <Route  exact path="/sandbox/:id" render={(props) => <SandboxDetail  {...props}/>}/>
              <Route  exact path="/myprofile" render={(props) => <Profile {...props}/>}/>
              <Route  exact path="/users" render={(props) => <Users {...props}/>}/>
              <Route  exact path="/profiles" render={(props) => <Roles {...props}/>}/>
              <Route  exact path="/adminlearninglab" render={(props) => <AdminLearningLab {...props}/>}/>
              <Route  exact path="/createlab/:id?" render={(props) => <CreateLab {...props}/>}/>
              <Route  exact path="/adminsandbox" render={(props) => <AdminSandbox {...props}/>}/>
              <Route  exact path="/createsandbox/:id?" render={(props) => <CreateSandbox {...props}/>}/>
              <Route  exact path="/adminimages" render={(props) => <AdminImages {...props}/>}/>
              <Route  exact path="/organization" render={(props) => <Organization {...props}/>} />
              <Route  exact path="/createorganization/:id?" render={(props) => <CreateOrganization {...props}/> }/>    
            </main>  
          </div>
        </Switch>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    loginDetails:state.auth
  }
}

const mapDispatchToProps = dispatch => ({
  logout:() => {
    dispatch(doLogout())
  }
})

Main.propTypes = {
  classes:PropTypes.object.isRequired,
  logout:PropTypes.func
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Main));

