import * as ActionTypes from './ActionTypes';
import { baseUrl, baseAuth } from "../shared/baseUrl";
import axios  from 'axios';


export const doLogin = (data) => dispatch => {
    dispatch(authLoginLoading())
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(`${baseAuth}api-token-auth/`,data,config)
        .then(res => {
            localStorage.setItem("user",JSON.stringify(res.data))
            dispatch(authLoginSuccess(res.data)) 
        })
        .catch(err => dispatch(authLoginFailed(err)))
}
export const fetchSandbox = () => dispatch => {
    dispatch(SandboxLoading());
    axios.get(`${baseUrl}sandbox/getSandbox`)
        .then(res => dispatch(getSandbox(res.data)))
        .catch(err => dispatch(SandBoxFailed(err)))
}

export const fetchSandboxByid = (id) => dispatch => {
    dispatch(SandboxLoading())
    axios.get(`${baseUrl}sandbox/getsandbox/${id}`)
        .then(res => dispatch(getSandboxById(res.data)))
        .catch(err => dispatch(SandBoxFailed(err)))
}

export const postSandbox = (data) => dispatch => {
    dispatch(SandboxLoading())
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(`${baseUrl}sandbox/createsandbox`,data,config)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchSandbox())
            }
        })
        .catch(err => dispatch(SandBoxFailed(err)))
}

export const upgardeSandbox = (id,data) => dispatch => {
    dispatch(SandboxLoading())
    axios.put(`${baseUrl}sandbox/updatesandbox?id=${id}`,data)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchSandbox())
            }
        })
        .catch(err => dispatch(SandBoxFailed(err)))
}

export const removeSandbox = (id) => dispatch => {
    dispatch(SandboxLoading())
    axios.delete(`${baseUrl}sandbox/deletesandbox?id=${id}`)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchSandbox())
            }
        })
        .catch(err => dispatch(SandBoxFailed(err))) 
}

export const fetchImage = () => dispatch => {
    dispatch(ImageLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 'Authorization': `Token ${token}` }
    }
    axios.get(`${baseAuth}instances`,config)
        .then(res => dispatch(getImage(res.data)))
        .catch(err => dispatch(ImageFailed(err)))
}

export const postImage = (name,cpu,ram,storage) => dispatch => {
    dispatch(ImageLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {
        headers: { 'Authorization': `Token ${token}` }
    }
    const data = {
        "instance_name":name,
        "cpu":cpu,
        "ram":ram,
        "storage":storage
    }
    axios.post(`${baseAuth}instances`,data,config)
        .then((res) => {
            if(res.status === 201){
                dispatch(fetchImage())
            }
        })
        .catch(err => dispatch(ImageFailed(err)))
}

export const removeImage = (id) => dispatch => {
    dispatch(ImageLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {
        headers: { 'Authorization': `Token ${token}` }
    }
    axios.delete(`${baseAuth}instances/${id}`,config)
        .then((res) => {
            if(res.status === 204){
                dispatch(fetchImage())
            }
        })
        .catch(err => dispatch(ImageFailed(err)))
}

export const updateImageStatus = (id,data) => dispatch => {
    dispatch(ImageLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {
        headers: { 'Authorization': `Token ${token}` }
    }
    axios.patch(`${baseAuth}instances/${id}/freeze-instance-change`, data, config)
        .then(res => {
            if(res.status === 200){
                dispatch(fetchImage())
            }
        })
        .catch(err => dispatch(ImageFailed(err)))
}


export const fetchSection = (id) => dispatch => {
    dispatch(SectionLoading())
    axios.get(`${baseUrl}section/allsection?id=${id}`)
        .then(res => dispatch(getSection(res.data)))
        .catch(err => dispatch(Sectionfailed(err)))
}

export const postSection = (name,sandboxid) => dispatch => {
    dispatch(SectionLoading())
    const data = { "name":name, "sandbox":sandboxid }
    axios.post(`${baseUrl}section/createsection`,data)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchSection(sandboxid))
            }
        })
        .catch(err => dispatch(Sectionfailed(err)))
}

export const renameSection = (id,name,sandboxid) => dispatch => {
    dispatch(SectionLoading())
    const data = { "name":name,"sandbox":sandboxid }
    axios.put(`${baseUrl}section/updatesection?id=${id}`,data)
        .then((res) =>  {
                if(res.status === 200){
                dispatch(fetchSection(sandboxid))
            }
        })
        .catch(err => dispatch(Sectionfailed(err)))
}

export const removeSection = (id) => dispatch => {
    dispatch(SectionLoading())
    axios.delete(`${baseUrl}section/deleteSection?id=${id}`)
        .then((res) => {
                if(res.status === 200){
                dispatch(deleteSection(id))
            }
        })
        .catch(err => dispatch(Sectionfailed(err)))
}

export const fetchSubSection = () => dispatch => {
    dispatch(SubSectionLoading())
    axios.get(`${baseUrl}subsection/allsubsec`)
        .then(res => dispatch(getSubSection(res.data)))
        .catch(err => dispatch(SubSectionfailed(err)))
}

export const postSubSection = (name,section) => dispatch => {
    dispatch(SubSectionLoading())
    const data = { "name":name,"section":section }
    axios.post(`${baseUrl}subsection/createsubsec`,data)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchSubSection())
            }
        })
        .catch(err => dispatch(SubSectionfailed(err)))

}

export const renameSubSection = (id,name,section) => dispatch => {
    dispatch(SubSectionLoading())
    const data = { "name":name,"section":section }
    axios.put(`${baseUrl}subsection/updatesubsec?id=${id}`,data)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchSubSection())
            }
        })
        .catch(err => dispatch(SubSectionfailed(err)))
}

export const removeSubSection = (id) => dispatch => {
    dispatch(SubSectionLoading())
    axios.delete(`${baseUrl}subsection/deletesubsec?id=${id}`)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchSubSection())
            }
        })
        .catch(err => dispatch(SubSectionfailed(err)))
}

export const fetchExercise = () => dispatch => {
    dispatch(ExerciseLoading())
    axios.get(`${baseUrl}exercise/allexercise`)
        .then(res => dispatch(getExercise(res.data)))
        .catch(err => dispatch(Exercisefailed(err)))
}

export const postExercise = (name,subsection,section) => dispatch => {
    dispatch(ExerciseLoading())
    const data = {"name":name,"subsection":subsection,"section":section}

    axios.post(`${baseUrl}exercise/createexercise`,data)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchExercise())
            }
        })
        .catch(err => dispatch(Exercisefailed(err)))
}

export const renameExercise = (id,name,subsection,section) => dispatch => {
    dispatch(ExerciseLoading())
    const data = {"name":name,"subsection":subsection,"section":section}
    axios.put(`${baseUrl}exercise/updateexercise?id=${id}`,data)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchExercise())
            }
        })
        .catch(err => dispatch(Exercisefailed(err)))
}

export const removeExercise = (id) => dispatch => {
    dispatch(ExerciseLoading())
    axios.delete(`${baseUrl}exercise/deleteexercise?id=${id}`)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchExercise())
            }
        })
        .catch(err => dispatch(Exercisefailed(err)))
}

export const authLoginLoading = () => ({
    type:ActionTypes.AUTH_LOGIN_LOADING
})

export const authLoginSuccess = authDetails => ({
    type:ActionTypes.AUTH_LOGIN_SUCCESS,
    payload:authDetails
})

export const authLoginFailed = err => ({
    type:ActionTypes.AUTH_LOGIN_ERROR,
    payload:err
})

export const ExerciseLoading = () => ({
    type:ActionTypes.EXERCISE_LOADING  
})

export const Exercisefailed = err => ({
    type:ActionTypes.EXERCISE_FAILED,
    payload:err
})



export const getExercise = exercise => ({
    type:ActionTypes.GET_EXERCISE,
    payload:exercise
})



export const SectionLoading = () => ({
    type:ActionTypes.SECTION_LOADING
})

export const Sectionfailed = err => ({
    type:ActionTypes.SECTION_FAILED,
    payload:err
})

export const getSection = section => ({
    type:ActionTypes.GET_SECTION,
    payload:section
})

export const deleteSection = (id) => ({
    type:ActionTypes.DELETE_SECTION,
    payload:{
        id
    }
})


export const getSubSection = subsec => ({
    type:ActionTypes.GET_SUBSECTION,
    payload:subsec
})





export const SubSectionLoading = () => ({
    type:ActionTypes.SUBSECTION_LOADING,
})

export const SubSectionfailed = err => ({
    type:ActionTypes.SUBSECTION_FAILED,
    payload:err
})


export const SandboxLoading = () => ({
    type:ActionTypes.SANDBOX_LOADING
})

export const SandBoxFailed = errmessage => ({
    type:ActionTypes.SANDBOX_FAILED,
    payload:errmessage
})

export const getSandbox = sandbox => ({
    type:ActionTypes.GET_SANDBOX,
    payload:sandbox
})

export const getSandboxById = sandbox => ({
    type:ActionTypes.GET_SANDBOX_BY_ID,
    payload:sandbox
})




export const ImageLoading = () => ({
    type:ActionTypes.IMAGE_LOADING
})

export const ImageFailed = errmessage => ({
    type:ActionTypes.IMAGE_FAILED,
    payload:errmessage
})


export const getImage = image => ({
    type:ActionTypes.GET_IMAGE,
    payload:image
})

export const postCode = (code,exercise) => dispatch =>{
    dispatch(codeLoading())
    const data = { "taskcode":code,"exercise":exercise}
    axios.post(`${baseUrl}editor/saveTaskcode`,data)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchCode())
            }
        })
        .catch(err => dispatch(codeFail(err)))
}


export const fetchCode = () => dispatch => {
    dispatch(codeLoading())
    axios.get(`${baseUrl}editor/getTaskcode`)
        .then(res => dispatch(getCode(res.data)))
        .catch(err => dispatch(codeFail(err)))
}

export const mergeCode = (id,code) => dispatch => {
    dispatch(codeLoading())

    const data = {
        "taskcode":code
    }

    axios.put(`${baseUrl}editor/updateTaskcode/${id}`,data)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchCode())
            }
        })
         .catch(err => dispatch(codeFail(err)))

}

export const codeLoading = () => ({
    type:ActionTypes.CODE_LOADING
})

export const codeFail = err => ({
    type:ActionTypes.CODE_FAIL,
    payload:err
})

export const getCode = code => ({
    type:ActionTypes.GET_CODE,
    payload:code
})


export const doLogout = () => dispatch => {
    localStorage.removeItem("user")
    dispatch(logout())    
}

export const logout = () => ({
    type:ActionTypes.AUTH_LOGOUT,
})

export const postLab = (data) => dispatch => {
    dispatch(labloading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {
        headers: { 'Authorization': `Token ${token}` }
    }
    axios.post(`${baseAuth}labs`,data,config)
        .then((res) => {
           if(res.status === 201){
               dispatch(addLab(res.data))
               //dispatch(fetchLab())
           }
            
        })
        .catch(err => dispatch(laberror(err)))
}

export const fetchLab = () => dispatch => {
    dispatch(labloading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {
        headers: { 'Authorization': `Token ${token}` }
    }
    axios.get(`${baseAuth}labs`,config)
        .then((res) => dispatch(getLab(res.data)))
        .catch(err => dispatch(laberror(err)))
}

export const fetchLabByid = (id) => dispatch => {
    dispatch(labloading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {
        headers: { 'Authorization': `Token ${token}` }
    }
    axios.get(`${baseAuth}labs/${id}`,config)
        .then(res => dispatch(getLabById(res.data)))
        .catch(err => dispatch(laberror(err)))
}

export const removeLab = (id) => dispatch => {
    dispatch(labloading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {
        headers: { 'Authorization': `Token ${token}` }
    }
    axios.delete(`${baseAuth}labs/${id}`,config)
        .then(res => {
            if(res.status === 204){
                dispatch(fetchLab())
            }
        })
        .catch(err => dispatch(laberror(err)))
}

export const fetchlabinstances = (id) => dispatch => {
    dispatch(labloading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {
        headers: { 'Authorization': `Token ${token}` }
    }
    
    axios.get(`${baseAuth}labs/${id}/lab-instances`,config)
        .then(res => dispatch(getlabinstances(res.data)))
        .catch(err => laberror(err))
}

export const addLab = msg => ({
    type:ActionTypes.ADD_LAB,
    payload:msg
})

export const getLabById = lab => ({
    type:ActionTypes.GET_LAB_BY_ID,
    payload:lab
})

export const getLab = (lab) => ({
    type:ActionTypes.GET_LAB,
    payload:lab
})

export const getlabinstances = msg => ({
    type:ActionTypes.LAB_INSTANCES,
    payload:msg
})

export const labloading = () => ({
    type:ActionTypes.LAB_LOADING
})

export const laberror = (err) => ({
    type:ActionTypes.LAB_ERROR,
    payload:err
})



export const sendEmailLink = (data) => dispatch => {
    dispatch(sendEmailLoading())
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(`${baseAuth}users/forget-password`,data,config)
        .then(res => dispatch(sendEmailSuccess('Link successfully send to your email Address')))
        .catch(err => dispatch(sendEmailFailed('Email Does not Exists')))
}

export const sendEmailSuccess = (msg) => ({
    type:ActionTypes.SEND_EMAIL_SUCCESS,
    payload:msg
})

export const sendEmailFailed = (msg) => ({
    type:ActionTypes.SEND_EMAIL_FAILED,
    payload:msg
})

export const sendEmailLoading = () => ({
    type:ActionTypes.SEND_EMAIL_LOADING
})

export const updatePassword = (data) => dispatch => {
    dispatch(resetpasswordLoading())
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }
    axios.post(`${baseAuth}users/reset-password`,data,config)
        .then(res => dispatch(resetpasswordsuccess('Password updated successfully')))
        .catch(err => dispatch(resetpasswordfailed('Error while updating password')))

}

export const resetpasswordsuccess = (msg) => ({
    type:ActionTypes.RESET_PASSWORD_SUCCESS,
    payload:msg
})

export const resetpasswordfailed = (msg) => ({
    type:ActionTypes.RESET_PASSWORD_FAILED,
    payload:msg
})

export const resetpasswordLoading = () => ({
    type:ActionTypes.RESET_PASSWORD_LOADING
})

export const saveProfile = (id,data) => dispatch => {
    dispatch(userLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'content-type': 'multipart/form-data',
            'Authorization': `Token ${token}` 
        },

    }
    axios.patch(`${baseAuth}users/${id}`,data,config)
        .then(res => dispatch(updateUser(id,res.data)))
        .catch(err => dispatch(usererr(err)))
}

export const fetchUser = () => dispatch => {
    dispatch(userLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'Authorization': `Token ${token}` 
        },
    }   
    axios.get(`${baseAuth}users`,config)
        .then(res => dispatch(getUser(res.data)))
        .catch(err => dispatch(usererr(err)))    
}

export const postUser = (data,profileid) => dispatch => {
    dispatch(userLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'content-type': 'multipart/form-data',
            'Authorization': `Token ${token}` 
        },

    }
    axios.post(`${baseAuth}users`,data,config)
        .then((res) => {
            dispatch(addUser(res.data))
            if(res.data.role === "Organisation User"){
                const user = []
                user.push(res.data.id)
                const userdata = {
                    "user":user
                }
                dispatch(modifyProfile(profileid,userdata))
            }
        })
        .catch(err => dispatch(usererr(err)))
}

export const getUser = user => ({
    type:ActionTypes.GET_USERS,
    payload:{
        user
    }
})

export const addUser = user => ({
    type:ActionTypes.CREATE_USER,
    payload:{
        user
    }

})



export const userLoading = () => ({
    type:ActionTypes.USER_LOADING
})

export const usererr = err => ({
    type:ActionTypes.USER_ERROR,
    payload:err
})

export const postProfile = (data) => dispatch => {
    dispatch(proflieload())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'Authorization': `Token ${token}` 
        },

    }
    axios.post(`${baseAuth}profiles`,data,config)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchProfile())
            }
        })
        .catch(err => dispatch(profilerr(err)))
}

export const fetchProfile = () => dispatch => {
    dispatch(proflieload())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'Authorization': `Token ${token}` 
        },

    }
    axios.get(`${baseAuth}profiles`,config)
        .then(res => dispatch(getProfiles(res.data)))
        .catch(err => dispatch(profilerr(err)))
}

export const modifyProfile = (id,data) => dispatch => {
    dispatch(proflieload())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'Authorization': `Token ${token}` 
        },
    }
    axios.patch(`${baseAuth}profiles/${id}/attach_profile`,data,config)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchProfile())
            }
        })
        .catch(err => dispatch(profilerr(err)))
}

export const getProfiles = msg => ({
    type:ActionTypes.GET_PROFILES,
    payload:msg
})

export const proflieload = () => ({
    type:ActionTypes.PROFILE_LOADING
})



export const profilerr = err => ({
    type:ActionTypes.PROFILE_ERROR,
    payload:err
})

export const createOrganization = (data) =>  dispatch => {
    dispatch(organizationLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'Authorization': `Token ${token}` 
        },

    }
    axios.post(`${baseAuth}organizations`,data,config)
        .then(res => dispatch(addOrganization(res.data)))
        .catch(err => dispatch(organizationerror(err)))
}

export const fetchOrganization = () => dispatch => {
    dispatch(organizationLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'Authorization': `Token ${token}` 
        },

    }
    axios.get(`${baseAuth}organizations`,config)
        .then(res => dispatch(getOrganization(res.data)))
        .catch(err => dispatch(organizationerror(err)))
}

export const fetchOrganizationById = (id) =>  dispatch => {
    dispatch(organizationLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'Authorization': `Token ${token}` 
        },

    }
    axios.get(`${baseAuth}organizations/${id}`,config)
        .then(res => dispatch(getOrganizationById(res.data)))
        .catch(err => dispatch(organizationerror(err)))
}

export const renameOrganization = (id,data) => dispatch => {
    dispatch(organizationLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'Authorization': `Token ${token}` 
        },

    }
    axios.patch(`${baseAuth}organizations/${id}`,data,config)
        .then((res) => {
            if(res.status === 200){
                dispatch(fetchOrganization())
            }
        })
        .catch(err => dispatch(organizationerror(err)))
}

export const removeOrganization = (id) => dispatch => {
    dispatch(organizationLoading())
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'Authorization': `Token ${token}` 
        },

    }
    axios.delete(`${baseAuth}organizations/${id}`,config)
        .then(res => {
            if(res.status === 204){
                dispatch(fetchOrganization())
            }
        })
        .catch(err => dispatch(organizationerror(err)))
}

export const addOrganization = (org) => ({
    type:ActionTypes.CREATE_ORGANIZATION,
    payload:{
        org
    }
})

export const organizationLoading = () => ({
    type:ActionTypes.ORGANIZATION_LOADING
})

export const getOrganization = org => ({
    type:ActionTypes.GET_ORGANIZATION,
    payload:{
        org
    }
})

export const getOrganizationById = org => ({
    type:ActionTypes.GET_ORGANIZATION_BY_ID,
    payload:{
        org
    }
})



export const organizationerror = err => ({
    type:ActionTypes.ORGANIZATION_ERROR,
    payload:{
        err
    }
})

export const sendLabRequestmail = (labname) => dispatch => {
    dispatch(sendEmailLoading())
    const formdata = new FormData()
    formdata.append("lab_name",labname)
    const { token } = JSON.parse(localStorage.getItem("user"))
    const config = {     
        headers: { 
            'Authorization': `Token ${token}` 
        },

    }
    axios.post(`${baseAuth}labs/request`,formdata,config)
        .then(res => dispatch(sendEmailSuccess('Request has been successfully send')))
        .catch(err => dispatch(sendEmailFailed('Lab Does not Exists')))
}