import React,{ Component } from 'react';
import { TextField, Typography, Button, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { saveProfile } from './app/ActionCreator';
import { connect } from 'react-redux';


const styles = {
    root:{
        marginTop:'60px'
    },
    textField:{
        marginRight:'8px',
        marginLeft:'8px',
        width:'25ch'
    },
    buttonColor:{
        color:'white',
        background:'#143174',
        '&hover':{
            color:'white',
            background:'#143174'  
        },
        textAlign:'center',
        marginTop:'30px',
        marginLeft:'10px'
    }
}

class Profile extends Component{
    constructor(props){
        super(props)
        this.state = {
            email:'',
            firstname:'',
            lastname:'',
            assignedRole:'User',
            userid:''
        }
    }

    handleInputChange = (e) => {
        this.setState({[e.target.name]:e.target.value})
    }

    updateProfile = () => {
        const profileformdata = new FormData()
        profileformdata.append("role",this.state.assignedRole)
        profileformdata.append("email",this.state.email)
        profileformdata.append("first_name",this.state.firstname)
        profileformdata.append("last_name",this.state.lastname)

        this.props.saveProfileData(this.state.userid,profileformdata)
    }

    componentDidMount(){
        const { user } = JSON.parse(localStorage.getItem("user"))
        this.setState({email:user.email})
        this.setState({assignedRole:user.role})
        this.setState({firstname:user.first_name})
        this.setState({lastname:user.last_name})
        this.setState({userid:user.id})
    }

    render(){
        const { classes } = this.props
        return(
            <div className={classes.root}>
               <Typography variant="h1" style={{margin:'10px',color:'black'}}>Profile</Typography>
                <TextField 
                    id="emailid"
                    label="EmailId"
                    style={{margin:'10px'}}
                    fullWidth
                    value={this.state.email}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"/>
                <TextField 
                    id="firstname"
                    label="First Name"
                    style={{margin:'10px'}}
                    fullWidth
                    value={this.state.firstname}
                    margin="normal"
                    name="firstname"
                    onChange={this.handleInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"/>
                <TextField 
                    id="lastname"
                    label="Last Name"
                    style={{margin:'10px'}}
                    fullWidth
                    value={this.state.lastname}
                    margin="normal"
                    name="lastname"
                    onChange={this.handleInputChange}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined"/>
                <TextField 
                    id="role"
                    select
                    label="Assigned Role"
                    value={this.state.assignedRole}
                    fullWidth
                    style={{margin:'10px'}}
                    margin="normal"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="outlined">
                    <MenuItem value="Super Admin">Super Admin</MenuItem>
                    <MenuItem value="Deviare Organisation Admin">Deviare Organisation Admin</MenuItem>
                    <MenuItem value="Template User">Template User</MenuItem>
                    <MenuItem value="Lab Creator">Lab Creator</MenuItem>
                    <MenuItem value="Organisation Admin">Organisation Admin</MenuItem>
                    <MenuItem value="Organisation User">Organisation User</MenuItem>                       
                </TextField>
                <Button className={classes.buttonColor} fullWidth onClick={this.updateProfile}>Save</Button>              
            </div>
        )
    }
}

const mapStatetoProps = state => {
    return{
        userList:state.user
    }
}

const mapDispatchToProps = dispatch => ({
    saveProfileData:(id,data) => {
        dispatch(saveProfile(id,data))
    }
})

Profile.propTypes = {
    classes:PropTypes.object,
    saveProfileData:PropTypes.func,
    userList:PropTypes.object
}

export default connect(mapStatetoProps,mapDispatchToProps)(withStyles(styles)(Profile));