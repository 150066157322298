import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Paper, Typography } from '@material-ui/core';
import { ChevronRightRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  main: {
    height: 'auto',
    borderRadius: '15px',
    display: 'flex',
    padding: '10%',
    width:'auto'
  },
  mainActive: {
    backgroundColor: '#2881c7',
    color: '#FFFFFF',
  },
  mainHover: {
    backgroundColor: '#143174',
    color:'white',
    cursor: 'pointer',
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  title: {
    fontWeight: '900'
  },
}));

export default function TopNavigator({ title, isActive, handleClick }) {
  const classes = useStyles();

  return (
    <>
      <Paper
        className={clsx(
          classes.main,
          isActive ? classes.mainActive : classes.mainHover
        )}
        onClick={handleClick}>
        <div className={classes.container}>
          <Typography varient='h1' align='center' className={classes.title}>
            {title}
          </Typography>
          <ChevronRightRounded fontSize='large' />
        </div>
      </Paper>
    </>
  );
}

TopNavigator.propTypes = {
  title: PropTypes.string,
  isActive: PropTypes.bool,
  handleClick: PropTypes.func,
};