import * as ActionTypes from './ActionTypes';

const initialState = {
    isLoading:false,
    sandbox:[],
    sandboxDetail:{},
    errMess:null
}

export const sandbox = (state=initialState,action) => {
    switch(action.type){
        case ActionTypes.GET_SANDBOX:
            return {
                ...state,
                sandbox:action.payload,
                sandboxDetail:{},
                isLoading:false,
                errMess:null
            }
        case ActionTypes.GET_SANDBOX_BY_ID:
            return {
                ...state,
                sandbox:[],
                sandboxDetail:action.payload,
                isLoading:false,
                errMess:null
            }              
        case ActionTypes.SANDBOX_LOADING:
            return {
                ...state,
                sandbox:[],
                sandboxDetail:{},
                isLoading:true,
                errMess:null
            }
        case ActionTypes.SANDBOX_FAILED:
            return {
                ...state,
                sandbox:[],
                sandboxDetail:{},
                errMess:action.payload,
                isLoading:false
            }     
        default:
            return state           
    }
}