import * as ActionTypes from './ActionTypes';

const initialState = {
    userlist:[],
    usererr:null,
    isLoading:false
}

export const User = (state = initialState,action) => {
    switch(action.type){
        case ActionTypes.GET_USERS:
            return {
                ...state,
                userlist:action.payload.user,
                isLoading:false,
                usererr:null
            }
        case ActionTypes.CREATE_USER:
            return {
                ...state,
                userlist:state.userlist.concat(action.payload.user),
                isLoading:false,
                usererr:null
            }
        case ActionTypes.USER_LOADING:
            return {
                ...state,
                userlist:[],
                isLoading:true,
                usererr:null
            }
        case ActionTypes.USER_ERROR:
            return {
                ...state,
                userlist:[],
                isLoading:false,
                usererr:action.payload
            }
        default:
            return state                
    }
}