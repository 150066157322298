import React,{ Component } from 'react';
import  { Button, Typography, Grid, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Edit, Delete } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchSandbox,removeSandbox } from './app/ActionCreator';
import TableComponent from './Components/TableComponent';
import EditStatusComponent from './Components/EditStatusComponent';

const styles = {
    root:{
        marginTop:'60px',
    },
    typo:{
        flexGrow:1,
        textAlign:'center',
        marginTop:'30px',
        color:'black'
    },
    headingpaper:{
        width:'100%',
        height:40,
        marginTop:'30px',
        background:'white',
        borderColor:'white',
    },
    buttonColor:{
        color:'white',
        background:'#143174',
        '&hover':{
            color:'white',
            background:'#143174'  
        }
    }
}

class AdminSandbox extends Component {
    constructor(props){
        super(props)
        this.state = {

        }
    }

    componentDidMount(){
        this.props.fetchSandboxData()
    }
    
    render(){
        const { classes } = this.props

        const labs = []
        let modifylab = this.props.sandbox.sandbox.filter((x) => x.type === "Sandbox").map((v,i) => {
           const labdet = {
               "id":v._id,
               "title":v.title,
               "createdBy":v.createdBy,
               "mappedImage":v.instance,
               "status":v.status
           }
           labs.push(labdet)
       })   
        return(
            <div className={classes.root}>
                <Grid container direction="row" justify="flex-end" alignItems="flex-start">
                    <Button component={Link} to={'/createsandbox'} variant="contained"  className={classes.buttonColor}>
                        Create Sandbox
                    </Button> 
                </Grid>
                <Typography variant="h2" noWrap className={classes.typo}>
                    Sandbox Templates
                </Typography>
                <TableComponent
                    cols={[
                        {field:'id',headerName:'SlNo',flex:1},
                        {field:'title',headerName:'Template Name',flex:1},
                        {field:'createdBy',headerName:'User',flex:1},
                        {field:'mappedImage',headerName:'mappedImage',flex:1},
                        {field:'status',headerName:'Status',flex:1,renderCell:(value) => {
                            if(value.row.status === 'Published'){
                                return(
                                    <EditStatusComponent
                                        status={value.row.status}
                                        statuscolor='#32CD32'
                                        showDropDown={false}/>
                                )
                            }
                            else if(value.row.status === 'Unpublished'){
                                return(
                                    <EditStatusComponent
                                        status={value.row.status}
                                        statuscolor='red'
                                        showDropDown={false}/>
                                )
                            }
                        }},
                        {field:'action',headerName:'Action',sortable:false,disableClickEventBubbling:true,flex:1,renderCell:(params) => {
                            return(
                                <div style={{display:'flex'}}>
                                <Link to={`/createsandbox/${params.row.id}`}><IconButton><Edit/></IconButton></Link>
                                <IconButton onClick={() => this.props.removeSandboxData(params.row.id)}><Delete/></IconButton>
                              </div> 
                            )
                        }}
                    ]}
                    rows={labs}
                    filter={{
                        items:[
                            {
                                columnField:'',
                                operatorValue:'',
                                value:''
                            }
                        ]
                    }
                }/> 
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        sandbox:state.sandbox
    }
}
const mapDispatchToProps = dispatch => ({
    fetchSandboxData:() => {
        dispatch(fetchSandbox())
    },
    removeSandboxData:(id) => {
        dispatch(removeSandbox(id))
    } 
})

AdminSandbox.propTypes = {
    classes:PropTypes.object,
    sandbox:PropTypes.object,
    fetchSandboxData:PropTypes.func,
    removeSandboxData:PropTypes.func
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AdminSandbox))