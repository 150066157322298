import * as ActionTypes from './ActionTypes';

const initialState = {
    isLoding:false,
    section:[],
    errmess:null
}

export const Section = (state = initialState, action) => {
    switch(action.type){
        case ActionTypes.GET_SECTION:
            return {
                ...state,
                section:action.payload,
                isLoding:false,
                errmess:null
            }
        case ActionTypes.SECTION_LOADING:
            return {
                ...state,
                isLoding:true,
                errmess:null
            }
        case ActionTypes.SECTION_FAILED:
            return {
                ...state,
                isLoding:false,
                errmess:action.payload
            }
        case ActionTypes.DELETE_SECTION:
            return {
                ...state,
                section:state.section.filter((item) => item._id !== action.payload.id),
                isLoading:false,
                errmess:null
            }                
        default:
            return state;   
    }
}