import * as ActionTypes from './ActionTypes';

const initialState = {
    isLoading:false,
    code:[],
    err:null
}

export const codeEditor = (state = initialState,action) => {
    switch(action.type){
        case ActionTypes.GET_CODE:
            return {
                ...state,
                isLoading:false,
                code:action.payload,
                err:null
            }
        case ActionTypes.CODE_LOADING:
            return {
                ...state,
                isLoading:true,
                code:[],
                err:null
            } 
        case ActionTypes.CODE_FAIL:
            return {
                ...state,
                isLoading:false,
                code:[],
                err:action.payload
            }   
        default:
            return state;              
    }
}