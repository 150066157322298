import * as ActionTypes from './ActionTypes';


const initialState = {
    subSection:[],
    isLoading:false,
    err:null
}

export const subSection = (state = initialState,action) => {
    switch(action.type){
        case ActionTypes.GET_SUBSECTION:
            return {
                ...state,
                subSection:action.payload,
                isLoading:false,
                err:null
            }
        case ActionTypes.SUBSECTION_LOADING:
            return{
                ...state,
                subSection:[],
                isLoading:true,
                err:null
            }
        
        case ActionTypes.SUBSECTION_FAILED:
            return{
                ...state,
                subSection:[],
                isLoading:false,
                err:action.payload
            }

        default:
            return state        
    }
}