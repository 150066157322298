import React from 'react';
import { TextField, MenuItem, Button } from '@material-ui/core';
import PropTypes from 'prop-types';

export const InstanceFormComponent = (props) => {
    const {
        values: { name, cpu, ram, disk },
        errors,
        touched,
        handleSubmit,
        handleChange,
        isValid,
        setFieldTouched
    } = props;
        
        
    const change = (name, e) => {
        e.persist();
        handleChange(e);
        setFieldTouched(name, true, false);
    };

    return (
        <form onSubmit={handleSubmit}>
            <TextField 
                id="standard-basic" 
                label="Name"
                style={{width:'50ch',margin:10}}
                name="name"
                helperText={touched.name ? errors.name : ""}
                error={touched.name && Boolean(errors.name)}
                value={name}
                onChange={change.bind(null, "name")}
                InputLabelProps={{
                    shrink: true,
            }}/>  
            <TextField
                id="standard-cpu"
                label="CPU"
                select
                style={{width:'50ch',margin:10}}
                name="cpu"
                helperText={touched.cpu ? errors.cpu : ""}
                error={touched.cpu && Boolean(errors.cpu)}
                value={cpu}
                onChange={change.bind(null, "cpu")}
                InputLabelProps={{
                    shrink: true,
                }}>
                <MenuItem value="8">8</MenuItem>
                <MenuItem value="10">10</MenuItem>
                <MenuItem value="12">12</MenuItem>    
                <MenuItem value="14">14</MenuItem>
                <MenuItem value="16">16</MenuItem>
                <MenuItem value="18">18</MenuItem>
                <MenuItem value="20">20</MenuItem>
                <MenuItem value="22">22</MenuItem>
                <MenuItem value="24">24</MenuItem>
                <MenuItem value="26">26</MenuItem>
                <MenuItem value="28">28</MenuItem>
                <MenuItem value="30">30</MenuItem>
                <MenuItem value="32">32</MenuItem>     
            </TextField>
            <TextField
                id="standard-ram"
                label="RAM"
                style={{width:'50ch',margin:10}}
                name="ram"
                select
                helperText={touched.ram ? errors.ram : ""}
                error={touched.ram && Boolean(errors.ram)}
                value={ram}
                onChange={change.bind(null, "ram")}
                InputLabelProps={{
                    shrink: true,
                }}>
                <MenuItem value="16">16</MenuItem>
                <MenuItem value="20">20</MenuItem>
                <MenuItem value="24">24</MenuItem>    
                <MenuItem value="28">28</MenuItem>
                <MenuItem value="32">32</MenuItem>
                <MenuItem value="36">36</MenuItem>
                <MenuItem value="40">40</MenuItem>
                <MenuItem value="44">44</MenuItem>
                <MenuItem value="48">48</MenuItem>
                <MenuItem value="52">52</MenuItem>
                <MenuItem value="56">56</MenuItem>
                <MenuItem value="60">60</MenuItem>
                <MenuItem value="64">64</MenuItem>     
            </TextField>
            <TextField
                id="standard-disk"
                label="Disk"
                style={{width:'50ch',margin:10}}
                name="disk"
                select
                helperText={touched.disk ? errors.disk : ""}
                error={touched.disk && Boolean(errors.disk)}
                value={disk}
                onChange={change.bind(null, "disk")}
                InputLabelProps={{
                    shrink: true,
                }}>
                <MenuItem value="50">50</MenuItem>
                <MenuItem value="100">100</MenuItem>
                <MenuItem value="150">150</MenuItem>    
                <MenuItem value="200">200</MenuItem>
                <MenuItem value="250">250</MenuItem>
                <MenuItem value="300">300</MenuItem>
                <MenuItem value="350">350</MenuItem>
                <MenuItem value="400">400</MenuItem>
                <MenuItem value="450">450</MenuItem>
                <MenuItem value="500">500</MenuItem>     
            </TextField>
            <Button
                type="submit"
                fullWidth
                style={{marginTop:15,marginLeft:8}}
                color='primary'
                disabled={!isValid}>
                Submit
            </Button>
        </form>
    )
}

InstanceFormComponent.propTypes = {
    errors:PropTypes.object,
    values:PropTypes.object,
    touched:PropTypes.object,
    handleSubmit:PropTypes.func,
    handleChange:PropTypes.func,
    isValid:PropTypes.bool,
    setFieldTouched:PropTypes.func

}