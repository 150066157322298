import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import responsiveTheme from './theme';
import { Provider } from 'react-redux';
import { configureStore } from './app/configureStore';
import Main from './Main';

const store = configureStore();

class App extends Component{
    render(){
      return(
       <Provider store={store}> 
          <BrowserRouter>
            <ThemeProvider theme={responsiveTheme}>
              <div>
                <Main/>
              </div>
            </ThemeProvider>  
          </BrowserRouter>
        </Provider>
      )
    }
}

export default App;

