import React, { Component } from 'react';
import { Box,Paper, TextField, Button, Typography, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import mailicon from './assests/mailicon.png';
import { Link } from 'react-router-dom';
import { sendEmailLink } from './app/ActionCreator';
import { connect } from 'react-redux';

const styles = {
    headPaper:{
        borderWidth:2,
        borderColor:'white',
        background:'white'
    },
    buttonColor:{
        height:'20%',
        width:'90%',
        textAlign:'center',
        marginTop:20,
        marginLeft:20,
        marginRight:20,
        marginBottom:20,
        color:'white',
        background:'#143174',
        '&hover':{
            color:'white',
            background:'#143174'  
        }
    },
    typo:{
        flexGrow:1,
        textAlign:'center',
        marginTop:'30px',
        
    },
    img:{
        display:'block',
        marginLeft:'auto',
        marginRight:'auto',
        width:'40%',
        marginTop:'20px'
    }

}

class ForgotPassword extends Component {
    constructor(props){
        super(props)
        this.state = {
            EmailID:'',
            validateemailLink:false,
            showsuccess:false,
            showerror:false
        }
        this.handleInputchange = this.handleInputchange.bind(this);
    }

    handleInputchange = (e) => {
        this.setState({[e.target.name]:e.target.value})
    }

    validate = () => {
        const regexemail = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if(regexemail.test(this.state.EmailID) && this.state.EmailID !== null){
            this.setState({validateemailLink:false})
        }
        else{
            this.setState({validateemailLink:true})
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.emailsend.emailsend !== this.props.emailsend.emailsend){
            this.setState({showsuccess:true})
        }
        if(prevProps.emailerror.emailsenderror !== this.props.emailerror.emailsenderror){
            this.setState({showerror:true})
        }
    }

    sendInvite = () => {
        if(!this.state.validateemailLink){
            const emailform = new FormData()
            emailform.append('email',this.state.EmailID)
            this.props.sendEmailLinkData(emailform)
        }
    }

    errorsnackbar = () => {
        if(this.props.emailerror.emailsenderror !== null){
            return (
                <Snackbar
                    open={this.state.showerror}
                    autoHideDuration={3000}
                    onClose={() => this.setState({showerror:false})}>
                    <Alert severity="error">{this.props.emailerror.emailsenderror}</Alert>    
                </Snackbar>
            )
        }
        else {
            return null
        }
        
    }

    successsnackbar = () => {
        if(this.props.emailsend.emailsend !== null){
            return (
                <Snackbar
                    open={this.state.showsuccess}
                    autoHideDuration={3000}
                    onClose={() => this.setState({showsuccess:false})}>
                    <Alert severity="success">{this.props.emailsend.emailsend}</Alert>    
                </Snackbar>
            )
        }
        else {
            return null
        }
        
    }

    render(){
        const { classes } = this.props
        return(
            <div>
                <Box 
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="100vh">  
                    <Paper className={classes.headPaper}>
                        <Typography variant="h1" className={classes.typo}> Forgot Password? </Typography>
                        <Typography variant="body1" className={classes.typo} style={{margin:20}}> Enter your register Email below to receive password reset instructions </Typography>
                        <img src={mailicon} alt={mailicon} className={classes.img}/>
                        <TextField
                            id="standard-full-width"
                            label="Email"
                            name="EmailID"
                            value={this.state.EmailID}
                            style={{ marginTop:30, marginLeft:20,marginRight:20,marginBottom:30, width:'90%',height:'5%' }}
                            placeholder="Enter your Email Id"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            onChange={this.handleInputchange}
                            onBlur={this.validate}
                            onFocus={this.validate}
                            error={this.state.validateemailLink}
                            helperText={this.state.validateemailLink ? "Please Enter Valid EmailID" : ""}/>
                            <Button className={classes.buttonColor} onClick={this.sendInvite}>SEND A LINK </Button>
                            <Link to={"/"} style={{textDecoration:'none'}}><Typography variant="h6" className={classes.typo} style={{color:'black',marginBottom:20}}> Go Back to Login</Typography></Link>     
                    </Paper>
                </Box>
                {this.errorsnackbar()}
                {this.successsnackbar()}  
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        emailsend:state.sendemail,
        emailerror:state.sendemail
    }
}

const mapDispatchToProps = dispatch => ({
    sendEmailLinkData:(data) => {
        dispatch(sendEmailLink(data))
    }
})

ForgotPassword.propTypes = {
    classes:PropTypes.object.isRequired,
    emailsend:PropTypes.string,
    emailerror:PropTypes.string,
    sendEmailLinkData:PropTypes.func
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ForgotPassword))