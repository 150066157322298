import React,{ Component } from 'react';
import { Paper, Grid, TextField, MenuItem,Typography, MenuList, Grow, Popper, ClickAwayListener, Button, Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import SectionComponent from './Components/SectionComponent';
import DropFile from './Components/DropFile';
import { fetchSandboxByid, upgardeSandbox, postSandbox, fetchImage } from './app/ActionCreator';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { ArrowDropDown } from '@material-ui/icons';


const styles = {
    root:{
        marginTop:'60px'
    },
    typo:{
        flexGrow:1,
        textAlign:'center',
        marginTop:'30px',
        color:'black'
    },
    headingpaper:{
        width:'100%',
        height:40,
        marginTop:'30px',
        background:'white',
        borderColor:'white',
    },
    formPaper:{
        width:'100%',
        height:'50vh',
        marginTop:'20px',
        background:'white',
    },
    imageUploadField:{
        width:220,
    },
    formRoot:{
        margin:'8px',
        width: '25ch',
        border: 0
    },
    labelText:{
        marginTop:'15px'
    },
    textinput:{
        width:'45ch',
        marginLeft:'50px',
        height:5
    },
    descriptiontextinput:{
        marginLeft:'50px',
        width:'45ch',
        maxHeight:2,
        borderColor:'black',
        marginTop:'55px',
    },
    buttonColor:{
        color:'white',
        background:'#143174',
        '&hover':{
            color:'white',
            background:'#143174'  
        },
    }
    

}

class CreateLab extends Component { 
    constructor(props){
        super(props)
        this.state = {
            labTemplate:'',
            showMenu:false,
            Title:'',
            Description:'',
            mappedimage:null,
            sandboxstatus:'',
            labDuration:''
        }
        this.anchorRef = React.createRef()
        this.getImage = this.getImage.bind(this)
    }

    handleToogle = () => {
        this.setState({showMenu:!this.state.showMenu})
    }

    getImage(imageData){
        this.setState({mappedimage:imageData})
    }

    handleClose = (event) => {
        if(this.anchorRef.current && this.anchorRef.current.contains(event.target)){
            return;
        }
        this.setState({showMenu:false})
        this.setState({sandboxstatus:event.target.innerText})
    }

    handleListKeyDown = (event) => {
        if(event.key === 'Tab'){
            event.preventDefault();
            this.setState({showMenu:false})
        }
    }

    

    
    componentDidUpdate(prevProps,prevState){
        if(prevProps.sandbox.sandboxDetail !== this.props.sandbox.sandboxDetail){
            this.setState({Title:this.props.sandbox.sandboxDetail.title})
            this.setState({Description:this.props.sandbox.sandboxDetail.description})
            this.setState({labTemplate:this.props.sandbox.sandboxDetail.instance})
            this.setState({sandboxstatus:this.props.sandbox.sandboxDetail.status})
            this.setState({mappedimage:this.props.sandbox.sandboxDetail.mappedimage})
            this.setState({labDuration:this.props.sandbox.sandboxDetail.duration})
        }
    }

    componentDidMount = () => {
        if(this.props.match.params !== undefined && this.props.match.params.id !== undefined){
            this.props.fetchSandboxByidData(this.props.match.params.id)
        }
        
        
        this.anchorRef.current.focus();
        
        this.props.fetchImageData()
    }

    handleSection = () => {
        if(this.props.match.params !== undefined && this.props.match.params.id !== undefined){
            return(
                <SectionComponent id={this.props.match.params.id}/>
            )
        }
        else{
            return null
        }
    }

    
    
    

    saveSandbox = () => {
        const { user } = JSON.parse(localStorage.getItem("user"))
        const formData1 = new FormData()
        formData1.append("title",this.state.Title)
        formData1.append("description",this.state.Description)
        formData1.append("instance",this.state.labTemplate)
        formData1.append("createdBy",`${user.first_name} ${user.last_name}`)
        formData1.append("status",this.state.sandboxstatus)
        formData1.append("serverid","")
        formData1.append("type","Lab")
        formData1.append("duration",this.state.labDuration)

        if(!Array.isArray(this.state.mappedimage)){
            formData1.append("mappedimage",this.props.sandbox.sandboxDetail.mappedimage)
        }
        else{
            formData1.append("mappedimage",this.state.mappedimage[0],this.state.mappedimage[0].name)
        }

        if(this.props.match.params !== undefined && this.props.match.params.id !== undefined){
            this.props.upgardeSandboxData(this.props.match.params.id,formData1)   
        }
        else{
            this.props.postSandboxData(formData1)
        }  
    }

    handleInputChanges = (event) => {
        this.setState({[event.target.name]:event.target.value})
    }

    render(){
        const { classes } = this.props
        return(
            <div className={classes.root}>
                <div style={{display:'flex'}}>
                    <Typography variant="h3" noWrap className={classes.typo}>
                        Learning Lab Details
                    </Typography>
                    <div style={{justifyContent:'flex-end',marginTop:'15px'}}>
                       <Button 
                        color="primary"
                        ref={this.anchorRef}
                        aria-controls={this.state.showMenu ? 'menu-list-grow' : undefined}
                        aria-haspopup="true"
                        style={{marginRight:'40px'}}
                        onClick={this.handleToogle}
                        endIcon={<ArrowDropDown/>}>
                            Status
                       </Button>
                       <Popper open={this.state.showMenu} anchorEl={this.anchorRef.current} role={undefined} transition disablePortal>
                         {({ TransitionProps, placement }) => (
                            <Grow {...TransitionProps} 
                                style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}>
                                <Paper>
                                    <ClickAwayListener onClickAway={this.handleClose}>
                                        <MenuList autoFocusItem={this.state.showMenu} id="menu-list-grow" onKeyDown={this.handleListKeyDown}>
                                            <MenuItem onClick={this.handleClose}><Checkbox name="Published" checked={this.state.sandboxstatus === "Published"} />Published</MenuItem>
                                            <MenuItem onClick={this.handleClose}><Checkbox name="Unpublished" checked={this.state.sandboxstatus === "Unpublished"} />Unpublished</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>    
                            </Grow>
                         )}   
                       </Popper>
                       <Button variant="contained" component={Link} to={'/adminlearninglab'} className={classes.buttonColor} onClick={this.saveSandbox}>
                            Save
                       </Button>  
                    </div>
                </div>
                <Grid container style={{marginTop:'120px'}}>
                    <Grid item xs>
                        <div style={{padding:'50px'}}>
                            <DropFile image={this.state.mappedimage} receiveImage={this.getImage}/>
                        </div>
                    </Grid>  
                    <Grid item xs>
                        <TextField 
                            id="standard-full-width"
                            type="text"
                            label="Title"
                            name="Title"
                            value={this.state.Title}
                            fullWidth
                            margin="normal"
                            onChange={this.handleInputChanges}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className={classes.textinput}/>
                        <TextField 
                            id="multiline-basic-description"
                            multiline
                            name="Description"
                            label="Description"
                            rows={4}
                            margin="normal"
                            variant="outlined"
                            onChange={this.handleInputChanges}
                            value={this.state.Description}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            className={classes.descriptiontextinput}/>
                        <TextField 
                            id="labTemplate"
                            select
                            label="Template Name"
                            name="labTemplate"
                            value={this.state.labTemplate}
                            onChange={this.handleInputChanges}
                            className={classes.textinput}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{marginTop:'105px'}}>
                            {
                                this.props.Images.images.filter((x) => x.status === "Frozen" || x.status === "frozen").map((v,i) => {
                                    return (
                                        <MenuItem key={v.id} value={v.instance_name}>{v.instance_name}</MenuItem>
                                    )
                                })
                            }      
                        </TextField>
                        <TextField      
                            id="labDuration"
                            select
                            label="Duration of Lab(in hours)"
                            name="labDuration"
                            value={this.state.labDuration}
                            onChange={this.handleInputChanges}
                            className={classes.textinput}
                            variant="outlined"
                            InputLabelProps={{
                                shrink: true,
                            }}
                            style={{marginTop:'70px'}}>
                            <MenuItem value="10">10</MenuItem>
                            <MenuItem value="20">20</MenuItem>
                            <MenuItem value="30">30</MenuItem>
                            <MenuItem value="40">40</MenuItem>
                            <MenuItem value="50">50</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>
                {this.handleSection()}
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        sandbox:state.sandbox,
        Images:state.Images,
    }
}

const mapDispatchToProps = dispatch => ({
    fetchSandboxByidData:(id) => {
        dispatch(fetchSandboxByid(id))
    },
    postSandboxData:(data) => {
        dispatch(postSandbox(data))
    },
    upgardeSandboxData:(id,data) => {
        dispatch(upgardeSandbox(id,data))
    },
    fetchImageData:() => {
        dispatch(fetchImage())
    }
    
}) 

CreateLab.propTypes = {
    classes:PropTypes.object.isRequired,
    match:PropTypes.object.isRequired,
    sandbox:PropTypes.object,
    fetchSandboxByidData:PropTypes.func,
    postSandboxData:PropTypes.func,
    upgardeSandboxData:PropTypes.func,
    fetchImageData:PropTypes.func,
    Images:PropTypes.object
}
export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(CreateLab));