import * as ActionTypes from './ActionTypes';

const initialState = {
    isLoading:false,
    images:[],
    errMess:null
}

export const Images=(state=initialState,action) => {
    switch(action.type){
        case ActionTypes.GET_IMAGE:
            return {
                ...state,
                images:action.payload,
                isLoading:false,
                errMess:null
            }
        case ActionTypes.IMAGE_LOADING:
            return {
                ...state,
                isLoading:true,
                images:[],
                errMess:null
            }
        case ActionTypes.IMAGE_FAILED:
            return {
                ...state,
                isLoading:false,
                images:[],
                errMess:action.payload
            }    
        default:
            return state             
    }
}