import * as ActionTypes from './ActionTypes';

const initialState = {
    isLoading:false,
    resetpasswordsuccess:null,
    resetpasswordfailed:null
}

export const ResetPassword = (state = initialState, action) => {
    switch(action.type){
        case ActionTypes.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading:false,
                resetpasswordsuccess:action.payload,
                resetpasswordfailed:null
            }
        case ActionTypes.RESET_PASSWORD_FAILED:
            return {
                ...state,
                isLoading:false,
                resetpasswordsuccess:null,
                resetpasswordfailed:action.payload
            }
        case ActionTypes.RESET_PASSWORD_LOADING:
            return {
                ...state,
                isLoading:true,
                resetpasswordsuccess:null,
                resetpasswordfailed:null
            }
        default:
            return state            
    }

}