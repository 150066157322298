import React, { Component } from 'react';
import { Button, Input, IconButton } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { fetchSection, postSection, removeSection, renameSection, postSubSection } from '../app/ActionCreator';
import { TreeView, TreeItem } from '@material-ui/lab';
import { ExpandMore, ChevronRight, Add, Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';
import SubSectionComponent from './SubSectionComponent';


const styles = {
    subSectionbuttondiv: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
   
    buttonColor: {
        color: 'white',
        background: '#143174',
        '&hover': {
            color: 'white',
            background: '#143174'
        }
    },
    treeroot: {
        flexGrow: 1
    },
}


class SectionComponent extends Component {
    constructor(props){
        super(props)
        this.state = {
            allowEdit:false,
            parentSection:'',
            sectionid:0
        }
    }

    changeSection = (event) => {
        this.setState({parentSection:event.target.value})
    }

    deleteSect = (index) => {
        const secitem = this.props.section.section[index]
        this.props.removeSectionData(secitem._id)
    }
    createSections = () => {
        this.props.postSectionData("section1",this.props.id)
    }

    updateSect = () => {
        this.setState({allowEdit:false})
        this.props.renameSectionData(this.state.sectionid,this.state.parentSection,this.props.id)    
    }

    handleToogle = (index) => {
        this.setState({allowEdit:!this.state.allowEdit})
        const secitem = this.props.section.section[index]
        this.setState({sectionid:secitem._id})
        this.setState({parentSection:secitem.name})
    }

    createSubSection = (sectid) => {
        this.props.postSubSectionData('Sub Section 1',sectid)
    }

    componentDidMount(){
        this.props.fetchSectionData(this.props.id)
    }

    displaysections = (secs) => {
        const { classes } = this.props
        if (secs && secs.length > 0) {
            return (
                <div style={{marginTop:'20px'}}>
                    {
                        secs.map((v, i) => {
                            return (
                                <div key={v._id}>
                                    <div style={{ display: 'flex', justifyContent:'space-between' }}>
                                        <TreeItem 
                                            key={i}
                                            nodeId={v._id} 
                                            label={
                                                this.state.sectionid === v._id && this.state.allowEdit ? <Input  
                                                    disableUnderline={true}
                                                    type="text"
                                                    value={this.state.parentSection}
                                                    onChange={this.changeSection}
                                                    onBlur={this.updateSect}/> : <p style={{color:'black'}}> {v.name} </p>
                                            }
                                            className={classes.treeroot} 
                                            onDoubleClick={() => this.handleToogle(i)}>
                                            <SubSectionComponent id={v._id}/>    
                                        </TreeItem>
                                        <div style={{display:'flex',justifyContent:'flex-end',position:'static',height:50}}>
                                           <IconButton onClick={() => this.deleteSect(i)}>
                                               <Delete />
                                            </IconButton>     
                                        </div>
                                    </div>  
                                    <div className={classes.subSectionbuttondiv}>
                                        <Button color="primary" onClick={() => this.createSubSection(v._id)} startIcon={<Add />}>Add Subsection</Button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            )
        }
        else {
            return null
        }
    }

    render(){
        const { classes } = this.props
        return(
            <div style={{ marginTop: '80px' }}>
                <div className={classes.subSectionbuttondiv}>
                    <Button variant="contained" className={classes.buttonColor} onClick={this.createSections} startIcon={<Add />}> Add Section </Button>
                </div>
                <TreeView
                    className={classes.treeroot}
                    defaultCollapseIcon={<ExpandMore />}
                    defaultExpandIcon={<ChevronRight />}
                    multiSelect>
                    {this.displaysections(this.props.section.section)}
                </TreeView>
            </div>
        )
    }
}



const mapStateToProps = state => {
    return {
        section: state.section,
    };
};

const mapDispatchToProps = dispatch => ({
    fetchSectionData:(id) => {
        dispatch(fetchSection(id))
    },
    postSectionData:(name,sandboxid) => {
        dispatch(postSection(name,sandboxid))
    },
    renameSectionData:(id,name,sandboxid) => {
        dispatch(renameSection(id,name,sandboxid))
    },
    removeSectionData:(id) => {
        dispatch(removeSection(id))
    },
    postSubSectionData:(name,section) => {
        dispatch(postSubSection(name,section))
    }
    
})

SectionComponent.propTypes = {
    classes: PropTypes.object.isRequired,
    id:PropTypes.string.isRequired,
    section: PropTypes.object,
    fetchSectionData:PropTypes.func,
    postSectionData:PropTypes.func,
    renameSectionData:PropTypes.func,
    removeSectionData:PropTypes.func,
    postSubSectionData:PropTypes.func
}


export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SectionComponent));