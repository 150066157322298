import React, { Component } from 'react';
import { Input, IconButton, Button } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';
import { withStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { TreeItem } from '@material-ui/lab';
import { Delete } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { fetchExercise, renameExercise, removeExercise, postCode, fetchCode, mergeCode } from '../app/ActionCreator';

const styles = {
    subSectionbuttondiv: {
        display: 'flex',
        justifyContent: 'flex-end'
    },
   
    buttonColor: {
        color: 'white',
        background: '#143174',
        marginTop:'20px',
        '&hover': {
            color: 'white',
            background: '#143174'
        }
    },
    treeroot: {
        flexGrow: 1
    },
}


class ExerciseComponent extends Component {
    constructor(props){
        super(props)
        this.state = {
            exerid:0,
            exercisename:'',
            subsecid:0,
            secid:0,
            codecontent:''
        }
    }


    changeSection = (event) => {
        this.setState({exercisename:event.target.value})
    }

    handleToogle = (index) => {
        this.setState({allowEdit:!this.state.allowEdit})
        const exeritem = this.props.exercise.excersie.filter(x => x._id === index)
        this.setState({exerid:exeritem[0]._id})
        this.setState({exercisename:exeritem[0].name})
        this.setState({subsecid:exeritem[0].subsection_id})
        this.setState({secid:exeritem[0].section._id})
    }

    deleteExer = (exid) => {
        this.props.removeExerciseData(exid)
    }

    handleEditorChange = (content, editor) => {
        if(content !== null){
            this.setState({codecontent:content})
        }
    }

    componentDidMount(){
        this.props.fetchExerciseData()
        this.props.fetchCodeData()
    }

    

    updateExercise = () => {
        this.props.renameExerciseData(this.state.exerid,this.state.exercisename,this.state.subsecid,this.state.secid)
    }

    sendCode = (id) => {
        const editorContent = this.props.code.code.filter(x => x.exercise._id === id)
        if(editorContent && editorContent.length > 0) {
            this.props.mergeCodeData(editorContent[0]._id,this.state.codecontent)
            
        }
        else{
            this.props.postCodeData(this.state.codecontent,id)
        }
        
    }

    handelEditor = (id) => {
        const editorContent = this.props && this.props.code && this.props.code.code.filter(x => x.exercise._id === id)

        if(editorContent && editorContent.length > 0){
            const htmlData = editorContent[0].taskcode
            return(
                <Editor
                    apiKey='33ijhb50nlmlpu6ov7b61sjlh5tnus61jpz4l7ey57mx844x'
                    initialValue={htmlData}
                    init={{
                        height:500,
                        width:'100%',
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                            'undo redo | link image | formatselect | Table | code | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help', 
                                                            
                            image_title: true,
                            automatic_uploads: true,
                            file_picker_types: 'image',
                            file_picker_callback: function (cb, value, meta) {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');
                                                                
                            input.onchange = function () {
                                var file = this.files[0];
                                                              
                                var reader = new FileReader();
                                reader.onload = function () {
                                    /*
                                        Note: Now we need to register the blob in TinyMCEs image blob
                                        registry. In the next release this part hopefully won't be
                                        necessary, as we are looking to handle it internally.
                                    */
                                    var id = 'blobid' + (new Date()).getTime();
                                    var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                                    var base64 = reader.result.split(',')[1];
                                    var blobInfo = blobCache.create(id, file, base64);
                                        blobCache.add(blobInfo);
                                        /* call the callback and populate the Title field with the file name */
                                        cb(blobInfo.blobUri(), { title: file.name });
                                    };
                                    reader.readAsDataURL(file);
                                };
                                input.click();
                            },
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }
                    }
                    onEditorChange={this.handleEditorChange}/>
            )
        }
        else {
            return(
                <Editor
                    apiKey='33ijhb50nlmlpu6ov7b61sjlh5tnus61jpz4l7ey57mx844x'
                    initialValue=""
                    init={{
                        height:500,
                        width:'100%',
                        menubar: false,
                        plugins: [
                            'advlist autolink lists link image charmap print preview anchor',
                            'searchreplace visualblocks code fullscreen',
                            'insertdatetime media table paste code help wordcount'
                        ],
                        toolbar:
                            'undo redo | link image | formatselect | Table | code | bold italic backcolor | \
                            alignleft aligncenter alignright alignjustify | \
                            bullist numlist outdent indent | removeformat | help', 
                                                        
                            image_title: true,
                            automatic_uploads: true,
                            file_picker_types: 'image',
                            file_picker_callback: function (cb, value, meta) {
                            var input = document.createElement('input');
                            input.setAttribute('type', 'file');
                            input.setAttribute('accept', 'image/*');
                                                            
                            input.onchange = function () {
                                var file = this.files[0];
                                                          
                                var reader = new FileReader();
                                reader.onload = function () {
                                    /*
                                    Note: Now we need to register the blob in TinyMCEs image blob
                                    registry. In the next release this part hopefully won't be
                                    necessary, as we are looking to handle it internally.
                                    */
                                    var id = 'blobid' + (new Date()).getTime();
                                    var blobCache =  tinymce.activeEditor.editorUpload.blobCache;
                                    var base64 = reader.result.split(',')[1];
                                    var blobInfo = blobCache.create(id, file, base64);
                                        blobCache.add(blobInfo);
                                        /* call the callback and populate the Title field with the file name */
                                        cb(blobInfo.blobUri(), { title: file.name });
                                    };
                                    reader.readAsDataURL(file);
                                };
                                input.click();
                            },
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }
                    }
                onEditorChange={this.handleEditorChange}/>
            )
        }
    }

    render(){
        const { classes } = this.props

        const exerarray = this.props && this.props.exercise && this.props.exercise.excersie.filter(x => x.subsection._id === this.props.subsecid && x.section._id === this.props.secid)

        if(exerarray && exerarray.length > 0){
            return(
                <div>
                  {
                    exerarray.map((v,i) => {
                        return(
                            <div key={v._id} style={{margin:'5px'}}>
                                <div style={{display:'flex',justifyContent:'space-between'}}>
                                    <TreeItem 
                                        nodeId={v._id}
                                        label={
                                            this.state.exerid === v._id && this.state.allowEdit ? <Input  
                                            disableUnderline={true}
                                            type="text"
                                            value={this.state.exercisename}
                                            onChange={this.changeSection}
                                            onBlur={this.updateExercise}/> : <p style={{color:'black'}}> {v.name} </p>
                                        }
                                        className={classes.treeroot} 
                                        onDoubleClick={() => this.handleToogle(v._id)}>
                                        <div style={{marginTop:'10px'}}>    
                                            {this.handelEditor(v._id)}
                                            <Button variant="contained" className={classes.buttonColor} onClick={() => this.sendCode(v._id)}>Submit</Button>
                                        </div>
                                    </TreeItem>
                                    <div style={{display:'flex',justifyContent:'flex-end',position:'static',height:50}}>
                                        <IconButton onClick={() => this.deleteExer(v._id)}>
                                            <Delete/>
                                        </IconButton>     
                                    </div>
                                </div>
                            </div>
                        )  
                      })
                    
                  }  
                </div>
            )
        }
        else {
            return null;
        }
    }
}

const mapStateToProps = state => {
    return{
        exercise: state.exercise,
        code:state.code
    }
} 

const mapDispatchToProps = dispatch => ({
    fetchExerciseData:() => {
        dispatch(fetchExercise())
    },
    renameExerciseData:(id,name,subsection,section) => {
        dispatch(renameExercise(id,name,subsection,section))
    },
    removeExerciseData:(id) => {
        dispatch(removeExercise(id))
    },
    postCodeData:(code,exercise) => {
        dispatch(postCode(code,exercise))
    },
    fetchCodeData:() => {
        dispatch(fetchCode())
    },
    mergeCodeData:(id,code) => {
        dispatch(mergeCode(id,code))
    }
    
})

ExerciseComponent.propTypes = {
    subsecid:PropTypes.string.isRequired,
    secid:PropTypes.string.isRequired,
    classes:PropTypes.object,
    exercise:PropTypes.object,
    fetchExerciseData:PropTypes.func,
    renameExerciseData:PropTypes.func,
    removeExerciseData:PropTypes.func,
    postCodeData:PropTypes.func,
    fetchCodeData:PropTypes.func,
    mergeCodeData:PropTypes.func,
    code:PropTypes.object
}

export default connect(mapStateToProps,mapDispatchToProps) (withStyles(styles)(ExerciseComponent))