import * as ActionTypes from './ActionTypes';

const initialState = {
    profiles:[],
    profileloading:false,
    profilerr:null
}

export const Profiles = (state = initialState,action) => {
    switch(action.type){
        case ActionTypes.GET_PROFILES:
            return {
                ...state,
                profiles:action.payload,
                profileloading:false,
                profilerr:null
            }
        case ActionTypes.PROFILE_LOADING:
            return {
                ...state,
                profiles:[],
                profilerr:null,
                profileloading:true
            }
        case ActionTypes.PROFILE_ERROR:
            return {
                ...state,
                profiles:[],
                profilerr:action.payload,
                profileloading:false
            }    
        default: 
            return state               
    }
}

