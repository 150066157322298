import React, { Component } from 'react';
import { Grid, Typography, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button} from '@material-ui/core';
import { AccessAlarm, Close } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import { fetchSandboxByid, fetchLabByid, removeLab,fetchlabinstances ,fetchLab } from './app/ActionCreator';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AccordionComponent from './Components/AccordionComponent';
import Modals from './Components/Modals';
import { Link } from 'react-router-dom';
import TableComponent  from 'Components/TableComponent';


const styles = {
    root:{
        marginTop:'60px'
    },
    typo:{
        flexGrow:1,
        color:'#32CD32'
    },
    buttonColor:{
        color:'white',
        background:'#143174',
        '&hover':{
            color:'white',
            background:'#143174'  
        }
    },
    terminatebuttonColor:{
        color:'white',
        background:'red',
        '&hover':{
            color:'white',
            background:'red'  
        }
    },
    listroot: {
        maxWidth: 260,
        marginTop:'60px'
      },
    buttonlist:{
        color:'white',
        backgroundColor:'#143174'
    },
    papaerdiv:{
        background:'white',
        height:300,
        marginTop:'60px',
        width:600,
        marginLeft:'20px'
    },
    dialog:{
        width:400,
        justifyContent:'center',
        alignSelf:'center'
    }
}

class SandboxDetail extends Component {
    constructor(props){
        super(props)
        this.state = {
           timer:0,
           currenttime:{},
           exerciseid:0,
           showModal:false,
           showDialog:false,
           username:'',
           labDetail:{},
           id:''
          
        }
        this.time = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);
    }

    secondsToTime = (secs) => {
        var days = Math.floor(secs/86400);
        var remainingDays = secs - (days * 86400);

        var hours = Math.floor(remainingDays/3600);
        var remainingHours = remainingDays - (hours * 3600);


        var minutes = Math.floor(remainingHours/60);
        var remainingMinutes = remainingHours - (minutes * 60);
        var seconds = Math.ceil(remainingMinutes)


        let obj = {
            "d":days,
            "h": hours,
            "m": minutes,
            "s": seconds
        };
        return obj;
        
    }

    componentDidMount(){
        this.props.fetchSandboxByidData(this.props.match.params.id)
        this.props.fetchLabData()
       
    }

    static getDerivedStateFromProps(props,state){
        if(state.labname !== null){
            const { user } = JSON.parse(localStorage.getItem("user"))
            const labdetail = props.lab.labs.filter(x => x.lab_name === state.labname && x.created_by.id === user.id)
            return {
                labDetail:labdetail
            }
        }
        else {
            return null
        }
    }

    componentDidUpdate(prevProps,prevState){
        if(prevProps.sandbox.sandboxDetail !== this.props.sandbox.sandboxDetail ){
            
            this.setState({labname:this.props.sandbox.sandboxDetail.title})
            
        }
        if(prevState.labDetail !== this.state.labDetail && this.state.labDetail.length > 0 ){
           
            const { id, lifetime, created_at } =  this.state.labDetail[0];
            const currentDate = new Date().getTime()
            
            const liftimeDate = new Date(created_at)
            const labendtimeDate = liftimeDate.setHours(liftimeDate.getHours() + lifetime)
            const labendtime = new Date(labendtimeDate).getTime()
            const labDurationtime = labendtime - currentDate
            this.setState({timer:labDurationtime/1000})
            this.setState({id:id})
            this.props.fetchlabinstancesdata(id)
        }
        
        if(prevState.timer !== this.state.timer){
           this.startTimer()
        }
        if(prevState.id !== this.state.id){
            this.props.fetchLabByidData(this.state.id)
        }
      
        
    }


    startTimer() {
        if (this.time === 0 && this.state.timer  > 0) {
          this.time = setInterval(this.countDown, 1000);
        }
    }
    
    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.timer - 1;
        this.setState({
          currenttime: this.secondsToTime(seconds),
          timer: seconds,
        });
        
        // Check if we're at zero.
        if (seconds === 0) { 
          clearInterval(this.time);
        }
    }

    exerLabContentid = (id) => {
        this.setState({exerciseid:id})
    }

    openDialog = () => {
        this.setState({showDialog:true})
    }

    closeDialog = () => {
        this.setState({showDialog:false})
    }

    terminatesandbox = () => {
        this.props.removeLabData(this.state.id)
        this.setState({showDialog:false})
    }
    
    render(){
        const { classes } = this.props
        
        return (
            <div className={classes.root}>
               <Typography variant="h2" style={{flexGrow:1,textAlign:'center',marginTop:'30px',marginBottom:'30px',color:'black'}}>{this.props.sandbox.sandboxDetail.title}</Typography> 
               <Grid container>
                   <Grid item xs={3}>
                      <Typography variant="body1" className={classes.typo}>{this.props.sandbox.sandboxDetail.status}</Typography>  
                   </Grid>
                   <Grid item xs={3}>
                        <div style={{display:'flex'}}>
                            <AccessAlarm/>
                            <Typography variant="body1">{this.state.currenttime.d}d : {this.state.currenttime.h}h : {this.state.currenttime.m} m : {this.state.currenttime.s} s</Typography>
                        </div>
                   </Grid>
                   <Grid item xs={3}>
                    <Button variant="contained" className={classes.buttonColor} onClick={() => this.setState({showModal:true})}>Sandbox Details</Button>
                   </Grid>
                   <Grid item xs={3}>
                    <Button variant="contained" className={classes.terminatebuttonColor} onClick={this.openDialog}>Terminate</Button>
                    <Dialog open={this.state.showDialog} onClose={this.closeDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description" maxWidth='xs'>
                        <DialogTitle id="alert-dialog-title">{"Exit Sandbox"}</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description">
                                Are you Sure that you want to quit sandbox? 
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button component={Link} to={'/sandboxes'} onClick={this.terminatesandbox} color="primary">
                                Yes
                            </Button>
                            <Button onClick={this.closeDialog} color="primary" autoFocus>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>   
                   </Grid>
                </Grid>
                <AccordionComponent id={this.props.match.params.id}/>
                <Modals 
                    show={this.state.showModal}
                    title="Sandbox Details" 
                    handleClose={() => this.setState({showModal:false})}
                    showSubmit={false}
                    size="lg">
                    <div>   
                        <div style={{display:'flex'}}>
                            <Typography variant="h4" noWrap style={{color:'black',margin:5}}>Name:</Typography>
                            <Typography variant="h6" noWrap style={{color:'black',margin:5}}>{this.props.sandbox.sandboxDetail.title}</Typography>
                        </div>
                        <div style={{display:'flex'}}>
                            <Typography variant="h4" noWrap style={{color:'black',margin:5}}>Status:</Typography>
                            <Typography variant="h6" noWrap style={{color:'black',margin:5}}>{this.props.lab.labDetail.status}</Typography>
                        </div>
                        <div style={{display:'flex'}}>
                            <Typography variant="h4" noWrap style={{color:'black',margin:5}}>IpAddress:</Typography>
                            <Typography variant="h6" noWrap style={{color:'black',margin:5}}>{this.props.lab.labDetail.ip_address}</Typography>
                        </div>
                        {/* <div style={{display:'flex'}}>
                            <Typography variant="h4" noWrap style={{color:'black',margin:5}}>createdBy:</Typography>
                            <Typography variant="h6" noWrap style={{color:'black',margin:5}}>{created_by.first_name + created_by.last_name}</Typography>
                        </div> */}
                       {this.props.lab.labinstances.length > 0 ? 
                            <TableComponent
                                cols={[
                                    {
                                        field:'id',headerName:'UUId',flex:1
                                    },
                                    { 
                                        field:'vm_name',headerName:'VM Name',flex:1
                                    },
                                    {
                                        field:'port_number',headerName:'Port Number',flex:1
                                    },
                                    {
                                        field:'ip_address',headerName:'Internal Ip',flex:1
                                    },
                                    {
                                        field:'instance_port',headerName:'Instance Port',flex:1
                                    }
                                ]}
                                rows={this.props.lab.labinstances}
                                filter={{
                                    items:[
                                        {
                                            columnField:'', operatorValue:'',value:''
                                        }
                                    ]
                                }}>

                            </TableComponent>
                            :null}     
                    </div>
                </Modals>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        sandbox:state.sandbox,
        lab:state.lab,
    }
}

const mapDispatchToProps = dispatch => ({
    fetchSandboxByidData:(id) => {
        dispatch(fetchSandboxByid(id))
    },
    fetchLabByidData:(id) => {
        dispatch(fetchLabByid(id))
    },
    removeLabData:(id) => {
        dispatch(removeLab(id))
    },
    fetchlabinstancesdata:(id) => {
        dispatch(fetchlabinstances(id))
    },
    fetchLabData:() => {
        dispatch(fetchLab())
    }
})

SandboxDetail.propTypes = {
    classes:PropTypes.object.isRequired,
    sandbox:PropTypes.object,
    fetchSandboxByidData:PropTypes.func,
    match:PropTypes.object.isRequired,
    fetchLabByidData:PropTypes.func,
    lab:PropTypes.object,
    removeLabData:PropTypes.func,
    fetchlabinstancesdata:PropTypes.func,
    fetchLabData:PropTypes.func
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(SandboxDetail));