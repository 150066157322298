import * as ActionTypes from './ActionTypes';

const initialState = {
    loginDetails:{},
    isLoading:false,
    loginerror:null
}

export const Auth = (state = initialState,action) => {
   switch(action.type){
       case ActionTypes.AUTH_LOGIN_SUCCESS:
           return {
               ...state,
               loginDetails:action.payload,
               isLoading:false,
               loginerror:null
           }
        case ActionTypes.AUTH_LOGIN_ERROR: 
            return {
                ...state,
                loginDetails:{},
                isLoading:false,
                loginerror:action.payload
            }
        case ActionTypes.AUTH_LOGIN_LOADING:
            return {
                ...state,
                loginDetails:{},
                isLoading:true,
                loginerror:null
            }
        case ActionTypes.AUTH_LOGOUT:
            return {
                ...state,
                loginDetails:{},
                isLoading:false,
                loginerror:null
            }    
        default:
            return state;           
   } 
}