import React, { useEffect, useState } from 'react';
import { Typography, List, ListItem, ListItemText, Switch, TextField, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { connect } from 'react-redux';
import { fetchImage, postProfile, fetchOrganization } from '../app/ActionCreator';
import Modals from './Modals';
import PropTypes from 'prop-types';

function CreateRoleComponent(props){
    const [showModal,setshowModal] = useState(true)
    const [profile,setprofile] = useState('')
    const [lab,setlab] = useState([])
    const [showsnackbar,setshowsnackbar] = useState(false)
    
    
    const handleLabToggle = (sand) => {
        const uniquelab = lab.findIndex((x) => x._id === sand._id)
        if(uniquelab === -1){
           setlab(oldlab => [...oldlab,sand])
        }
        
    }

    useEffect(() => {
        props.fetchImageData()
        props.fetchOrganizationData()
    },[])

    const sendProfile = () => {
        const { user } = JSON.parse(localStorage.getItem("user"))
        const inst = props.Images.images.filter(val => lab.some(val1 => val.instance_name === val1.instance))
        const instance = []
        inst.map((v,i) => {
            if(instance.findIndex((v) => v.id) === -1){
                instance.push(v.id)
            }
        })
        const data = {
            "name":profile,
            "instance":instance,
            "organization":user.organization
        }
        props.postProfileData(data)
        setshowModal(false)
        setshowsnackbar(true)
    }

    const rolebasedAcess = () => {
        const { user } = JSON.parse(localStorage.getItem("user"))
        if(user.role === 'Super Admin' || user.role === 'Deviare Organisation Admin'){
            return (
                <div>
                    <Typography variant="h1" noWrap style={{marginTop:20}}>
                        List of Labs
                    </Typography>
                    <List component="nav" aria-label="secondary lab folder">
                    {
                                
                        data.filter((x) => x.type === "Lab").map((v,i) => {
                        const labsubscribe = lab.some((x) => x._id === v._id)
                        return(
                                <ListItem key={v._id} >
                                    <ListItemText primary={v.title}/>
                                    <Switch
                                        edge="end"
                                        checked={labsubscribe}
                                        onChange={() => handleLabToggle(v)}
                                        inputProps={{ 'aria-labelledby': 'switch-list-label-lab' }}/>
                                </ListItem>
                            )
                        })
                    }
                    </List>
                    <Typography variant="h1" noWrap style={{marginTop:15}}>
                        List of Sandbox
                    </Typography >
                    <List component="nav" aria-label="secondary sandbox folder">
                    {
                        data.filter((x) => x.type === "Sandbox").map((v,i) => {
                            const labsubscribe = lab.some((x) => x._id === v._id)
                            return(
                                <ListItem key={v._id} >
                                    <ListItemText primary={v.title}/>
                                        <Switch
                                            edge="end"
                                            checked={labsubscribe}
                                            onChange={() => handleLabToggle(v)}
                                            inputProps={{ 'aria-labelledby': 'switch-list-label-sandbox' }}/>
                                    </ListItem>
                                )
                            })
                        }
                    </List>  
                </div>   
            )
        }
        else {
            const orglab = props.organization.organizationList.filter((x) => x.id === user.organization)
            const orginatnce = orglab && orglab.length > 0 && orglab[0].allowed_labs[0]
            return (
                <div>
                    <Typography variant="h1" noWrap style={{marginTop:20}}>
                        List of Labs
                    </Typography>
                    <List component="nav" aria-label="secondary lab folder">
                    {
                                
                        data.filter((x) => x.type === "Lab" && x.instance === orginatnce).map((v,i) => {
                        const labsubscribe = lab.some((x) => x._id === v._id)
                        return(
                                <ListItem key={v._id} >
                                    <ListItemText primary={v.title}/>
                                    <Switch
                                        edge="end"
                                        checked={labsubscribe}
                                        onChange={() => handleLabToggle(v)}
                                        inputProps={{ 'aria-labelledby': 'switch-list-label-lab' }}/>
                                </ListItem>
                            )
                        })
                    }
                    </List>
                    <Typography variant="h1" noWrap style={{marginTop:15}}>
                        List of Sandbox
                    </Typography >
                    <List component="nav" aria-label="secondary sandbox folder">
                    {
                        data.filter((x) => x.type === "Sandbox" && x.instance === orginatnce).map((v,i) => {
                            const labsubscribe = lab.some((x) => x._id === v._id)
                            return(
                                <ListItem key={v._id} >
                                    <ListItemText primary={v.title}/>
                                        <Switch
                                            edge="end"
                                            checked={labsubscribe}
                                            onChange={() => handleLabToggle(v)}
                                            inputProps={{ 'aria-labelledby': 'switch-list-label-sandbox' }}/>
                                    </ListItem>
                                )
                            })
                        }
                    </List>  
                </div>   
            )
        }
    }
    

    const { data } = props
    
   
    return (
        <div>
            <Modals
                show={showModal}
                title="Create Profile"
                handleClose={() => setshowModal(false)}
                showSubmit={true}
                onPress={sendProfile}
                size="xs">
                <div>
                    <TextField
                        id="profile"
                        label="Name of Profile"
                        fullWidth
                        style={{margin:8}}
                        margin="normal"
                        onChange={(e) => setprofile(e.target.value)}
                        InputLabelProps={{
                            shrink: true,
                        }}/>
                    {rolebasedAcess()}
                </div>   
            </Modals>
            {props.profile && props.profile.profiles.length > 0 ? <Snackbar open={showsnackbar} autoHideDuration={3000} onClose={() => setshowsnackbar(false)} anchorOrigin={{vertical:'top',horizontal:'right'}}>
                <Alert severity="success"> Profile Added Successfully </Alert>
            </Snackbar> : null}
            {props.profile && props.profile.profilerr !== null ?  <Snackbar open={showsnackbar} autoHideDuration={3000} onClose={() => setshowsnackbar(false)} anchorOrigin={{vertical:'top',horizontal:'right'}}>
                <Alert severity="error"> Sorry Please Try Again! </Alert>
            </Snackbar> : null}           
        </div>
    )

}

CreateRoleComponent.propTypes = {
    data:PropTypes.array.isRequired,
    Images:PropTypes.object,
    postProfileData:PropTypes.func,
    fetchImageData:PropTypes.func,
    profile:PropTypes.object,
    organization:PropTypes.object,
    fetchOrganizationData:PropTypes.func
}

const mapStateToProps = state => {
    return {
        Images:state.Images,
        profile:state.profile,
        organization:state.organization
    }
}

const mapDispatchToProps = dispatch => ({
    postProfileData:(data) => {
        dispatch(postProfile(data))
    },
    fetchImageData:() => {
        dispatch(fetchImage())
    },
    fetchOrganizationData:() => {
        dispatch(fetchOrganization())
    }
})



export default connect(mapStateToProps,mapDispatchToProps)(CreateRoleComponent)