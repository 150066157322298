import React, { Component } from 'react';
import { Box, Paper, TextField, Button, Typography, IconButton, InputAdornment, Snackbar, CircularProgress } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { withStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import logo from './deviare-logo-light.svg';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';
import { doLogin } from './app/ActionCreator';
import { connect } from 'react-redux';

const styles = {
    headPaper:{
        borderWidth:2,
        borderColor:'white',
        background:'white'
    },
    buttonColor:{
        height:'20%',
        width:'90%',
        textAlign:'center',
        marginTop:60,
        marginLeft:20,
        marginRight:20,
        marginBottom:40,
        color:'white',
        background:'#143174',
        '&hover':{
            color:'white',
            background:'#143174'  
        }
    },
    typo:{
        flexGrow:1,
        textAlign:'center',
        marginTop:'30px'
    },
    img:{
        display:'block',
        marginLeft:'auto',
        marginRight:'auto',
        width:'40%',
        marginTop:'20px'
    }

}

class Login extends Component {
    constructor(props){
        super(props)
        this.state = {
            Email:'',
            Password:'',
            validateEmail:false,
            validatePassword:false,
            showpassword:false,
            showSnackbar:false,
            
        }
        this.handleInputchange = this.handleInputchange.bind(this);
    }

    toggleShowPassword = () => {
        this.setState({showpassword:!this.state.showpassword})
    }

    handleInputchange = (e) => {
        this.setState({[e.target.name]:e.target.value})
    }

    static getDerivedStateFromProps(props,state){
        if(props.loginerror.loginerror !== null){
            return {
                showSnackbar:true
            }
        }
        if(props.loginDetails.loginDetails !== null){
            return {
                showSnackbar:true
            }
        }
        return null
    }

    validate = (type) => {
        if(type === 'email'){
            if(this.state.Email !== ''){
                this.setState({validateEmail:false})
            }
            else{
                this.setState({validateEmail:true})
            }
        }
        else {
            if(this.state.Password !== ''){
                this.setState({validatePassword:false})
            }
            else{
                this.setState({validatePassword:true})
            } 
        }
        
    }

    signIn = () => {
        if(!this.state.validateEmail && !this.state.validatePassword){
            const loginForm = new FormData()
            loginForm.append("email",this.state.Email)
            loginForm.append("password",this.state.Password)
            this.props.doLoginData(loginForm)
        }
        else{
            alert("Please Enter Details")
        }
    }

    displayLoader = () => {
        if(this.props.isLoading.isLoading)
        {
            return (
                <div style={{display:'flex',justifyContent:'center',alignItems:'center',height: "100vh", width: "100vw",position:'absolute'}}>
                    <CircularProgress/>
                </div>
            )
        }
        else {
            return null
        }
    }

    displayError = () => {
        if(this.props.loginerror.loginerror !== null){
            return(
                <Snackbar
                    open={this.state.showSnackbar}
                    autoHideDuration={3000}
                    onClose={() => this.setState({showSnackbar:false})}>
                    <Alert severity="error">Login Failed Invalid Email or Password</Alert>    
                </Snackbar>
            )
        }
        else {
            return null;
        }
    }
    
    navigateToDashboard = () => {
        if(this.props.loginDetails.loginDetails !== null){
            return(
                <Link to={"/dashboard"}/>
                
            )
        }

    }

    render(){
        const { classes } = this.props
        if(localStorage.getItem("user") !== null){
            return(
                <Redirect to={"/dashboard"}/>
            )
        }
        else
        {
            return(
                <div>
                    {this.displayLoader()}    
                    <Box 
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        minHeight="100vh">  
                        <Paper className={classes.headPaper}>
                            <Typography variant="h1" className={classes.typo}>Welcome</Typography>
                            <img src={logo} alt={logo} className={classes.img}/>
                            <TextField
                                id="standard-full-width"
                                label="EmailID"
                                name="Email"
                                error={this.state.validateEmail}
                                value={this.state.Email}
                                helperText={this.state.validateEmail ? "Please Enter EmailId":""}
                                style={{ marginTop:30, marginLeft:20,marginRight:20,marginBottom:30, width:'90%',height:'5%' }}
                                placeholder="Enter your Email Id"
                                onBlur={() => this.validate("email")}
                                onFocus={() => this.validate("email")}
                                onChange={this.handleInputchange}
                                InputLabelProps={{
                                    shrink: true,
                                }}/>
                            <TextField 
                                id="standard-full-width"
                                label="Password"
                                style={{ margin:20, width:'90%',height:'5%' }}
                                placeholder="Enter your Password"
                                name="Password"
                                value={this.state.Password}
                                error={this.state.validatePassword}
                                helperText={this.state.validatePassword ? "Please Enter Password":""}
                                type={this.state.showpassword ? "text" : "password"}
                                onBlur={() => this.validate("password")}
                                onFocus={() => this.validate("password")}
                                onChange={this.handleInputchange}
                                InputLabelProps={{
                                    shrink:true,
                                }}
                                InputProps={{
                                    endAdornment:(
                                        <InputAdornment position="end">
                                            <IconButton aria-label="toggle password visibility" onClick={this.toggleShowPassword}>
                                                {this.state.showpassword ? <Visibility/> : <VisibilityOff/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}/>
                            <Link to={"/forgot-password"} style={{textDecoration:'none'}}><Typography variant="body2" noWrap style={{marginTop:'20px',flexGrow:1,textAlign:'right',marginRight:'20px'}}>Forgot Password?</Typography></Link>    
                            <Button className={classes.buttonColor} onClick={this.signIn}>Login</Button>   
                        </Paper>
                    </Box> 
                    {this.displayError()}
                    {this.navigateToDashboard()}
                </div>
            )
        }
    }
}

const mapStateToProps = state => {
    return {
        loginDetails:state.auth,
        loginerror:state.auth,
        isLoading:state.auth
    }
}

const mapDispatchToProps = dispatch => ({
    doLoginData:(data) => {
        dispatch(doLogin(data))
    }
})

Login.propTypes = {
    classes:PropTypes.object.isRequired,
    doLoginData:PropTypes.func,
    loginDetails:PropTypes.object,
    loginerror:PropTypes.object,
    isLoading:PropTypes.bool
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(Login));


