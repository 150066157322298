import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Paper, List, ListItem, ListItemText, Collapse, Typography, Divider } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';
import { fetchSection,fetchSubSection,fetchExercise,fetchCode } from '../app/ActionCreator';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';



const styles = {
    papaerdiv:{
        background:'white',
        height:'120ch',
        marginTop:'10px',
        width:'180ch',
        marginLeft:'20px'
    }, 
    root:{
        width: 350,
        background:'white'
    },
    subsecnested:{
        background:'#143174',
        '&:hover':{
           background:'#143174', 
        },
        borderRadius:10
    },
    exercisenested:{
        background:'#2881c7',
        '&:hover':{
            background:'#2881c7',
        },
        borderRadius:10
    }

}

class AccordionComponent extends Component {
    constructor(props){
        super(props)
        this.state = {
            expandsec:false,
            expandsubsec:false,
            sectid:'',
            subsectid:'',
            exerid:'',
            exercisename:''
        }
    }

    handleSectChange = (id) => {
        this.setState({sectid:id})
        this.setState({expandsec:!this.state.expandsec})
    }

    handleSubSectChange = (id) => {
        this.setState({subsectid:id})
        this.setState({expandsubsec:!this.state.expandsubsec})
    }

    handleExerciseDetail = (id,name) => {
        this.setState({exerid:id})
        this.setState({exercisename:name})
    }

    handleEditorData(){
        const { classes } = this.props
        const editorContent = this.props.code.code.filter((x) => x.exercise._id === this.state.exerid)
        if(editorContent && editorContent.length > 0){
            const htmlData = editorContent[0].taskcode
            return (
                <div style={{marginTop:'40px',marginLeft:'30px'}}>
                    <Typography variant="h1" style={{color:'black',marginLeft:'15px'}}>{this.state.exercisename}</Typography>
                    <Paper variant="outlined" className={classes.papaerdiv}>
                        <div dangerouslySetInnerHTML={{__html:htmlData}} style={{padding:10}}></div>  
                    </Paper>
                </div>
            )
        }
    }


    componentDidMount(){
        this.props.fetchSectionData(this.props.id)
        this.props.fetchSubSectionData()
        this.props.fetchExerciseData()
        this.props.fetchCodeData()
    }

    handleExercise(){
        const exerarr = this.props.exercise.excersie.filter((x) => x.subsection._id === this.state.subsectid && x.section._id === this.state.sectid)
        if(exerarr && exerarr.length > 0){
            const { classes } = this.props
            return (
                <div style={{paddingLeft:'20px',paddingRight:'15px'}}>
                    {exerarr.map((v,i) => {
                        return(
                            <>
                                <ListItem key={v._id} button onClick={() => this.handleExerciseDetail(v._id,v.name)} className={classes.exercisenested}>
                                    <ListItemText primary={v.name} style={{color:'white'}}/>
                                </ListItem>
                                <Divider style={{background:'white'}}/>
                           </>
                        )
                    })}
                </div>
            )
        }
        else{
            return null
        }
    }

    handleSubsection(){
        const subsect = this.props.subsection.subSection.filter((v) => v.section._id === this.state.sectid)
        if(subsect && subsect.length > 0){
            const{ classes } = this.props
            return (
                <div style={{paddingLeft:'20px',paddingRight:'30px'}}>
                   {subsect.map((v,i) => {
                        return(
                            <>
                            <ListItem key={v._id} button onClick={() => this.handleSubSectChange(v._id)} className={classes.subsecnested}>
                                <ListItemText primary={v.name} style={{color:'white'}}/>
                                {this.state.subsectid === v._id && this.state.expandsubsec ? <ExpandLess style={{color:'white'}}/>:<ExpandMore style={{color:'white'}}/>}
                            </ListItem>
                            <Divider style={{background:'white'}}/>
                            <Collapse in={this.state.subsectid === v._id && this.state.expandsubsec} timeout="auto" unmountOnExit>
                                <List component="div" disalePadding>
                                    {this.handleExercise()}
                                </List>
                            </Collapse>
                           </>
                        )      
                    })} 
                </div>    
            )
        }
        else {
            return null
        } 
    }

    handleSection(){
        if(this.props.section && this.props.section.section.length > 0){
            const{ classes } = this.props
            return (
                <div style={{marginTop:'40px'}}>
                   {this.props.section.section.map((v,i) => {
                       return (
                            <>
                                <ListItem key={v._id} button onClick={() => this.handleSectChange(v._id)} style={{background:'#C0C0C0',opacity:0.8,borderRadius:10}}>
                                    <ListItemText primary={v.name} style={{color:'black',fontWeight:'bold'}}/>
                                {this.state.sectid === v._id && this.state.expandsec ? <ExpandLess/>:<ExpandMore/>}
                                </ListItem>
                                <Divider style={{background:'white'}}/>
                                <Collapse in={ this.state.sectid === v._id && this.state.expandsec} timeout="auto" unmountOnExit>
                                    <List component="div" disalePadding>
                                        {this.handleSubsection()}
                                    </List>
                                </Collapse>
                            </>
                       )
                   })} 
                </div>
            )
        }
        else {
            return null
        }
    }

    render(){
        const{ classes } = this.props
        return(
            <div>
                <div style={{display:'flex'}}>
                    <List component="nav" aria-labelledby="nested-list-subheader" className={classes.root}>
                        {this.handleSection()}
                    </List>
                    <div>
                        {this.handleEditorData()}
                    </div>
                </div>    
            </div>
        )
    }
}

const mapStateToProps = state => {
    return{
        section:state.section,
        subsection: state.subsection,
        exercise:state.exercise,
        code:state.code
    }
}

const mapDispatchToProps = dispatch => ({
    fetchSectionData:(id) => {
        dispatch(fetchSection(id))
    },
    fetchSubSectionData:() => {
        dispatch(fetchSubSection())
    },
    fetchExerciseData:() => {
        dispatch(fetchExercise())
    },
    fetchCodeData:() => {
        dispatch(fetchCode())
    }
})

AccordionComponent.propTypes = {
    classes:PropTypes.object.isRequired,
    id:PropTypes.string.isRequired,
    fetchSectionData:PropTypes.func,
    fetchSubSectionData:PropTypes.func,
    fetchExerciseData:PropTypes.func,
    fetchCodeData:PropTypes.func,
    section:PropTypes.object,
    subsection:PropTypes.object,
    exercise:PropTypes.object,
    code:PropTypes.object
}

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(AccordionComponent))


