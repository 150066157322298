import React, { useState, useRef} from 'react';
import Dropzone from 'react-dropzone'; 
import PropTypes from 'prop-types';
import './styles.css';
import { baseUrl } from '../shared/baseUrl';

  

function DropFile(props){

  const [files, setFiles] = useState(null);
  const [previewSrc, setPreviewSrc] = useState('');
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(false);
  const dropRef = useRef();
  

  
  
  const onDrop = (files) => {
    const [uploadedFile] = files;
    setFiles(uploadedFile);

    const fileReader = new FileReader();
    fileReader.onload = () => {
      setPreviewSrc(fileReader.result);
    };
    fileReader.readAsDataURL(uploadedFile);
    setIsPreviewAvailable(uploadedFile.name.match(/\.(jpeg|jpg|png)$/));
    //dropRef.current.style.border = '2px dashed #e9ebeb';
    props.receiveImage(files)
  };

  const updateBorder = (dragState) => {
    if (dragState === 'over') {
      dropRef.current.style.border = '2px solid #000';
    } else if (dragState === 'leave') {
      dropRef.current.style.border = '2px dashed #e9ebeb';
    }
  };

  if(props.image !== undefined &&  props.image !== null && typeof(props.image) === 'string' ) {
    const imageview = `${baseUrl}uploads/${props.image}`
    return(
      <div>
        <div className="upload-section">
          <img className="preview-image" src={imageview} alt="Preview" style={{display:'grid',width:250,height:200}}/>
        </div>  
      </div>
    ) 
  }

  else if (previewSrc !== null && isPreviewAvailable )
  {
    return(
      <div>
        <div className="upload-section">
          <div className="image-preview">
            <img className="preview-image" src={previewSrc} alt="Preview" style={{display:'grid',width:250,height:200}}/>
          </div>
        </div>
      </div>
    )
  }

  else {
    return(
      <div>
        <div className="upload-section">
          <Dropzone
            onDrop={onDrop}
            onDragEnter={() => updateBorder('over')}
            onDragLeave={() => updateBorder('leave')}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps({ className: 'drop-zone' })} ref={dropRef}>
                <input {...getInputProps()} />
                <p>Drag and drop a file OR click here to select a file</p>
                {files && (
                  <div>
                    <strong>Selected files:</strong> {files.name}
                  </div>
                )}
              </div>
            )}
          </Dropzone>
        </div>
      </div>
    )
  }
}
DropFile.propTypes = {
  image:PropTypes.string,
  receiveImage:PropTypes.func,
}

export default DropFile;